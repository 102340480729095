import { z } from 'zod';

import {
  NotificationType,
  NotificationActionType,
} from '@/utils/constants/notification.constants';
import { PaginationResponseSchema } from '@/schemas/common.schema';
import { PaymentMethod } from '@/utils/constants/payment.constant';
import { DiscountLabel } from '@/utils/constants/promotion.constant';

export const NotificationRequestSchema = z.void();
export type TNotificationRequestSchema = z.infer<
  typeof NotificationRequestSchema
>;

export const SingleNotificationResponseSchema = z.object({
  id: z.string().uuid(),
  companyId: z.string().uuid(),
  type: z.nativeEnum(NotificationType),
  actionType: z.nativeEnum(NotificationActionType),
  title: z.string(),
  data: z.object({
    teamId: z.string().uuid().nullable().optional(),
    userId: z.string().uuid().nullable().optional(),
    addedBy: z.string().nullable().optional(),
    userName: z.string().nullable().optional(),
    team: z.string().nullable().optional(),
    authUserId: z.string().uuid().nullable().optional(),
    orderId: z.string().uuid().nullable().optional(),
    orderCustomId: z.string().nullable().optional(),
    total: z.string().nullable().optional(),
    orderBy: z.string().nullable().optional(),
    email: z.string().nullable().optional(),
    teamName: z.string().nullable().optional(),
    deletedBy: z.string().nullable().optional(),
    role: z.string().nullable().optional(),
    paymentMethod: z.nativeEnum(PaymentMethod).nullable().optional(),
    sku: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    promoId: z.string().uuid().nullable().optional(),
    discount: z.string().nullable().optional(),
    productId: z.string().uuid().nullable().optional(),
    categoryId: z.string().uuid().nullable().optional(),
    productTypeId: z.string().uuid().nullable().optional(),
    price: z.union([z.string(), z.number()]).nullable().optional(),
    categories: z
      .array(
        z.object({
          id: z.string().uuid().nullable().optional(),
          name: z.string().nullable().optional(),
          products_count: z.number().nullable().optional(),
        }),
      )
      .nullable()
      .optional(),
    discountLabel: z.nativeEnum(DiscountLabel).nullable().optional(),
  }),
  createdAt: z.string(),
  updatedAt: z.string(),
  users: z.array(
    z.object({
      readAt: z.string().nullable(),
      userId: z.string().uuid(),
    }),
  ),
  timeAgo: z.string().nullable().optional(), // added manually to formatted notification
  isNotificationRead: z.boolean().nullable().optional(), // added manually to formatted notification
});

export type TSingleNotificationResponseSchema = z.infer<
  typeof SingleNotificationResponseSchema
>;

// all notifications
export const NotificationResponseSchema = z.object({
  data: z.array(SingleNotificationResponseSchema),
  meta: z.object({
    pagination: PaginationResponseSchema,
  }),
});

export type TNotificationResponseSchema = z.infer<
  typeof NotificationResponseSchema
>;

// notification mark as read
export const NotificationMarkAsReadResponseSchema = z.unknown();

// clear all notifications
export const ClearAllNotificationsResponseSchema = z.unknown();
