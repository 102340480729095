import { useCallback, useEffect, useState } from 'react';

import {
  EventDataInteface,
  extractSSEData,
} from '@/utils/helper/notifications.helper';
import {
  TSystemCurrencyFormats,
  TSystemDateFormats,
  TSystemTimeFormats,
} from '@/utils/constants/general.constant';
import { useUserStore } from '@/store';
import { SystemDefault } from '@/store/user-store-new';
import { useGetCompanyDetails } from '../company/useCompany';
import useNotificationStore from '@/store/notification.store';
import { API_ENDPOINT, V12_FINGERPRINT_KEY } from '@/utils/constants';
import { fingerprintGenerator } from '@/components/lib/fingerprintGenerator';

export const useNotificationsSSE = ({ signal }: { signal?: AbortSignal }) => {
  const { refetch: refetchCompanyDetails } = useGetCompanyDetails();
  const { auth, systemDefault, updateSystemDefault, removeStorage } =
    useUserStore();
  const { setv12SSEEventCount, isAllNotificationEmpty } =
    useNotificationStore();

  const token = auth?.token;

  const [isConnected, setIsConnected] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const checkForUpdates = (
    eventData: Omit<EventDataInteface, 'cartItemCount' | 'notificationCount'>,
    systemDefaultFromStore: SystemDefault,
  ) => {
    return (
      eventData.currency !== systemDefaultFromStore.currency ||
      eventData.dateFormat !== systemDefaultFromStore.dateFormat ||
      eventData.timeFormat !== systemDefaultFromStore.timeFormat
    );
  };

  const updateSystemDefaultSettings = (
    eventData: { currency: string; dateFormat: string; timeFormat: string },
    systemDefaultFromStore: SystemDefault,
  ) => {
    if (eventData.currency !== systemDefaultFromStore.currency) {
      updateSystemDefault({ currency: eventData.currency });
    }
    if (eventData.dateFormat !== systemDefaultFromStore.dateFormat) {
      updateSystemDefault({
        dateFormat: eventData.dateFormat as TSystemDateFormats,
      });
    }
    if (eventData.timeFormat !== systemDefaultFromStore.timeFormat) {
      updateSystemDefault({
        timeFormat: eventData.timeFormat as TSystemTimeFormats,
      });
    }
  };

  const reloadPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const updateNotificationCount = (
    eventData: EventDataInteface,
    systemDefaultFromStore: SystemDefault,
  ) => {
    setv12SSEEventCount({
      cartItemCount: eventData.cartItemCount,
      notificationCount: eventData.notificationCount,
      currency: systemDefaultFromStore.currency as TSystemCurrencyFormats,
      dateFormat: systemDefaultFromStore.dateFormat!,
      timeFormat: systemDefaultFromStore.timeFormat!,
    });
  };

  const clearAllNotification = useCallback(() => {
    setv12SSEEventCount({
      cartItemCount: 0,
      currency: systemDefault?.currency as TSystemCurrencyFormats,
      dateFormat: systemDefault ? systemDefault.dateFormat! : null,
      notificationCount: 0,
      timeFormat: systemDefault ? systemDefault.timeFormat! : null,
    });
  }, [systemDefault]);

  const handleSSEEvent = (
    eventData: {
      cartItemCount: number;
      notificationCount: number;
      currency: string;
      dateFormat: string;
      timeFormat: string;
    },
    systemDefaultFromStore: SystemDefault,
  ) => {
    const updatesRequired = checkForUpdates(eventData, systemDefaultFromStore);
    if (updatesRequired) {
      refetchCompanyDetails();
      updateSystemDefaultSettings(eventData, systemDefaultFromStore);
      reloadPage();
    }

    updateNotificationCount(eventData, systemDefaultFromStore);
  };

  const getServerEvent = useCallback(
    async (controller: AbortController, deviceFingerprint: string) => {
      const signals = [controller.signal];
      if (signal) signals.push(signal);
      try {
        const response = await fetch(
          `${import.meta.env.VITE_V12_BASE_URL}${API_ENDPOINT.NOTIFICATIONS.SSE_EVENTS}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'text/event-stream',
              [V12_FINGERPRINT_KEY]: deviceFingerprint,
            },
            signal: AbortSignal.any(signals),
          },
        );

        // Check for 401 Unauthorized
        if (response.status === 401) {
          console.warn('Unauthorized: Please log in again.');
          setIsError(true);
          setError('Session expired. Please log in again.');
          removeStorage();
          return; // Stop further execution
        }

        if (!response.ok) {
          throw new Error('Error retrieving server event');
        }

        const reader = response.body?.getReader();
        if (!reader) {
          throw new Error('No response body');
        }

        setIsConnected(true);
        setError(null);
        setIsError(false);

        const decoder = new TextDecoder();
        let buffer = '';

        let done = false;

        while (!done) {
          const { done: readerDone, value } = await reader.read();
          done = readerDone;
          if (value) {
            buffer += decoder.decode(value, { stream: true });
            const lines = buffer.split('\n\n');
            buffer = lines.pop() ?? '';

            const eventData = extractSSEData<{
              cartItemCount: number;
              notificationCount: number;
              currency: string;
              dateFormat: string;
              timeFormat: string;
            }>(lines);

            if (!isAllNotificationEmpty) {
              if (systemDefault && eventData) {
                handleSSEEvent(eventData, systemDefault);
              }
            } else {
              clearAllNotification();
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          if (e.name === 'AbortError') {
            console.warn('SSE connection aborted.');
          } else {
            console.error('SSE Error', e.message);
          }
          setIsError(true);
          setError(e.message);
        }
      }
    },
    [token, signal, setv12SSEEventCount, isAllNotificationEmpty, systemDefault],
  );

  useEffect(() => {
    if (!token) return;

    const controller = new AbortController();

    const start = async () => {
      const fingerprint = await fingerprintGenerator.getFingerprint();
      await getServerEvent(controller, fingerprint);
    };

    start();

    return () => {
      controller.abort();
    };
  }, [token, getServerEvent]);

  return { isConnected, isError, error };
};
