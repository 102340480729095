export const V12_OLD_DOMAIN = 'https://v12footwear.com';
export const V12_EXPLORE_CATEGORIES = 'https://v12footwear.com/collections/all';
export const V12_TERMS_AND_CONDITIONS_URL =
  'https://v12footwear.com/policies/terms-of-service';
export const V12_PRIVACY_POLICY_URL =
  'https://info.v12footwear.com/v12-footwear-policies';
export const V12_AGREEMENT_URL =
  'https://v12footwear.com/policies/terms-of-service';
export const V12_FAQ_URL = 'https://info.v12footwear.com/safetyfootwearfaqs';

export const V12_BASE_URL = import.meta.env.VITE_V12_BASE_URL;

export const V12_COUNTRY_STATE_CITY_URL = import.meta.env
  .VITE_COUNTRY_STATE_CITY_BASE_URL;
export const V12_COUNTRY_STATE_CITY_KEY = import.meta.env
  .VITE_COUNTRY_STATE_CITY_KEY;

import Logo_White_Reg from '@/assets/images/logos/Logo_White_Reg.svg';
import Logo_Black_Reg from '@/assets/images/logos/Logo_Black_Reg.svg';

export const LOGO = {
  WHITE_REG: Logo_White_Reg,
  BLACK_REG: Logo_Black_Reg,
};

export const V12_IMAGE_BASE_URL = import.meta.env.VITE_V12_FILE_UPLOAD_URL;

export const SHIPPING_CHARGE_RATE = import.meta.env.VITE_SHIPPING_CHARGE_RATE;
export const SHIPPING_CHARGE_THRESHOLD_AMOUNT = import.meta.env
  .VITE_SHIPPING_CHARGE_THRESHOLD_AMOUNT;

export const V12_FINGERPRINT_KEY = 'X-AUTH-FP';
