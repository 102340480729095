import { useNavigate } from 'react-router-dom';

import { Button, Modal } from '@/components';
import useNotificationStore from '@/store/notification.store';
import useClearAllNotifications from '@/components/hooks/api/notifications/useClearAllNotifications';

const ClearAllButton = ({
  isNotificationEmpty,
  isPathNotification,
}: {
  isNotificationEmpty?: boolean;
  isPathNotification?: boolean;
}) => {
  const navigate = useNavigate();

  const {
    clearNotificationDialogOpen,
    setClearNotificationDialogOpen,
    setActiveNotificationTab,
    resetNotificationStore,
    setIsNotificationEmpty,
    setv12SSEEventCount,
    v12SSEEventCount,
  } = useNotificationStore();

  const { mutate: clearAllNotifications } = useClearAllNotifications();

  return (
    <div className='mr-0 sm:mr-12'>
      <Button
        variant={isNotificationEmpty ? 'gray' : 'blue'}
        intent='tertiary'
        className='p-0 text-sm'
        style={{
          background: isNotificationEmpty ? 'transparent' : undefined,
        }}
        disabled={isNotificationEmpty}
        onClick={() => setClearNotificationDialogOpen(true)}
      >
        Clear All
      </Button>

      <Modal
        showModal={clearNotificationDialogOpen}
        setShowModal={(value) =>
          setClearNotificationDialogOpen(
            typeof value === 'function'
              ? value(clearNotificationDialogOpen)
              : value,
          )
        }
      >
        <Modal.Content className='md:min-w-[600px]'>
          <Modal.Title>
            <h6>Clear All Notifications</h6>
          </Modal.Title>
          <Modal.Body>
            Are you sure you want to clear all notifications? This action will
            clear all notifications.
          </Modal.Body>
          <Modal.Footer className='space-x-8'>
            <Modal.CloseButton
              data-cy='modal-close-button'
              onClick={() => setClearNotificationDialogOpen(false)}
            >
              Cancel
            </Modal.CloseButton>
            <Button
              type='submit'
              form='logoutForm'
              data-cy='apply-logout-button'
              onClick={() => {
                clearAllNotifications(undefined, {
                  onSuccess: () => {
                    setClearNotificationDialogOpen(false);
                    if (isPathNotification) {
                      navigate('/notifications?tab=all');
                    }
                    setActiveNotificationTab(
                      isPathNotification ? 'page' : 'tab',
                      'all',
                    );
                    resetNotificationStore();
                    setIsNotificationEmpty('all', true);

                    // Explicitly reset the SSE event count
                    setv12SSEEventCount({
                      ...v12SSEEventCount,
                      cartItemCount: 0,
                      notificationCount: 0,
                    });
                  },
                });
              }}
            >
              Clear
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default ClearAllButton;
