import { useContext } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { OptionProps, components } from 'react-select';
import { ErrorMessage } from '@hookform/error-message';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import {
  Button,
  Checkbox,
  Input,
  InputError,
  Label,
  Select,
} from '@/components';

import { handleApiError } from '@/utils/helper';
import { RoleOptions } from '@/data/Users.data';
import { CreateUserSchema, TCreateUser } from '@/schemas';
import { useAlert, useCheckEmailExist } from '@/components/hooks';
import { useGetAllTeamsList } from '@/components/hooks/api/useTeams';
import { limitNameWithLength } from '@/utils/helper/limitNameWithLength';
import { CreateUserTableContext } from '@/pages/users-and-teams/users/modals';

/**
 * @description Custom option component for the select input
 */
function CheckboxOption(props: OptionProps) {
  return (
    <components.Option {...props}>
      <div className='flex cursor-pointer items-center gap-x-2 rounded px-3 py-2 hover:bg-gray-100'>
        <Checkbox
          variant='blue'
          checked={props.isSelected}
          readOnly
        />
        <label
          className={`cursor-pointer text-sm text-gray-800 ${props.isSelected ? 'font-semibold' : 'font-normal'} `}
        >
          {props.label}
        </label>
      </div>
    </components.Option>
  );
}

/**
 * @description Form component for creating a new user
 */
export function CreateUserForm({
  setShowCreateUserModal,
}: {
  setShowCreateUserModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { setCreateUserList } = useContext(CreateUserTableContext);

  const { showAlert } = useAlert();

  const { query } = useGetAllTeamsList();

  const { data: teams, fetchNextPage, hasNextPage, isFetchingNextPage } = query;

  const handleScroll = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const teamsOptions = teams?.pages.flat().map((team) => ({
    value: team._id,
    label: limitNameWithLength(team.name, 25),
  }));

  const methods = useForm<TCreateUser>({
    mode: 'all',
    resolver: zodResolver(CreateUserSchema),
    defaultValues: { email: '', role: '', teams: [] },
  });

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors, isValid },
  } = methods;

  const { mutate, isPending } = useCheckEmailExist();

  const onSubmit: SubmitHandler<TCreateUser> = (data) => {
    mutate(
      { email: data.email },
      {
        onSuccess: ({ data: { isEmailExist } }) => {
          if (isEmailExist) {
            setError('email', {
              message: 'Email already exists',
              type: 'manual',
            });
          } else {
            setCreateUserList((prev) => [...prev, data]);
            reset();
            return;
          }
        },
        onError: (error) => {
          setShowCreateUserModal(false);
          showAlert(handleApiError(error), 'error');
        },
      },
    );
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='mb-5 flex flex-col flex-wrap gap-2 sm:flex-row'
      >
        <div className='flex flex-col items-baseline gap-4 space-x-2 sm:flex-row sm:gap-0'>
          <div className='w-full space-y-1 sm:w-1/3'>
            <Label htmlFor='email'>Email</Label>
            <Input
              type='email'
              name='email'
              placeholder='Enter email address'
              className=''
            />
          </div>
          <div className='w-full flex-1 space-y-1 sm:w-52'>
            <Label htmlFor='role'>Assign Role</Label>
            <Controller
              name='role'
              control={control}
              render={({ field: { name, onChange, onBlur, value } }) => (
                <Select
                  name={name}
                  onBlur={onBlur}
                  options={RoleOptions}
                  placeholder='Select role'
                  value={RoleOptions.filter((role) => role.value === value)}
                  onChange={(newValue: unknown) =>
                    onChange((newValue as { value: unknown }).value as string)
                  }
                  className={errors.role ? '!border-red-600 !bg-red-50' : ''}
                />
              )}
            />
            <ErrorMessage
              name='role'
              errors={errors}
              render={({ message }) => <InputError message={message} />}
            />
          </div>
          <div className='w-full space-y-1 sm:w-[unset]'>
            <Label htmlFor='teams'>Assign Team (Optional)</Label>
            <Controller
              name='teams'
              control={control}
              render={({ field: { onChange, value: newValue } }) => (
                <Select
                  isMulti
                  value={newValue}
                  options={teamsOptions}
                  onChange={onChange}
                  Option={CheckboxOption}
                  placeholder='Select teams'
                  onMenuScrollToBottom={handleScroll}
                  isLoading={isFetchingNextPage}
                  classNames={{
                    container: () => 'sm:!w-[240px]',
                    valueContainer: () => 'max-h-[80px]',
                    menu: () => '!z-20',
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className='mt-6 flex w-full flex-col md:w-[unset]'>
          <Button
            type='submit'
            disabled={!isValid || isPending}
          >
            Add
          </Button>
          <div />
        </div>
      </form>
    </FormProvider>
  );
}
