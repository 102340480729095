import { useParams } from 'react-router-dom';

import {
  NotificationActionType,
  NotificationType,
} from '@/utils/constants/notification.constants';
import {
  getDiscountLabel,
  getNotificationKey,
} from '@/utils/helper/notifications.helper';
import { Link } from '../link';
import { ROUTES } from '@/utils/constants';
import { getUserFullName, isEmpty } from '@/utils/helper';
import useNotificationStore from '@/store/notification.store';
import { formatCompanyPrice } from '@/utils/helper/country-locale';
import { PaymentMethod } from '@/utils/constants/payment.constant';
import { TSingleNotificationResponseSchema } from '@/schemas/api/notifications/notifications';

const NotificationLink = ({
  path,
  label,
  isSpecialPrice = false,
}: {
  path: string;
  label: string;
  isSpecialPrice?: boolean;
}) => {
  const { categoryId } = useParams();

  const { showNotificationModal, setShowNotificationModal } =
    useNotificationStore();

  return (
    <Link
      variant={'blue'}
      intent={'tertiary'}
      to={path}
      className='m-0 p-0'
      onClick={() => {
        if (!showNotificationModal) return;
        setShowNotificationModal(false);
        if (
          isSpecialPrice &&
          window.location.pathname.includes(ROUTES.PRODUCT.ALL_PRODUCTS) &&
          !isEmpty(categoryId)
        ) {
          window.location.reload();
        }
      }}
    >
      {label}
    </Link>
  );
};

const NotificationLabel = ({
  notification,
}: {
  notification: TSingleNotificationResponseSchema;
}) => {
  const { type, data, actionType } = notification;

  const {
    teamId,
    userId,
    addedBy,
    team,
    userName,
    authUserId,
    orderCustomId,
    orderId,
    orderBy,
    email,
    teamName,
    deletedBy,
    role,
    paymentMethod,
    total,
    discount,
    name,
    price,
    categories,
    discountLabel,
    sku,
    categoryId,
    productId,
    productTypeId,
    promoId,
  } = data;

  const getNotificationLabel = () => {
    switch (type) {
      case NotificationType.USERS:
        {
          const renderUser = () =>
            email ? (
              <span>{email}</span>
            ) : (
              <NotificationLink
                path={`/${ROUTES.USER_AND_TEAMS.USER.LIST}/${userId}`}
                label={userName ?? ''}
              />
            );

          const renderAddedBy = () => (
            <NotificationLink
              path={`/${ROUTES.USER_AND_TEAMS.USER.LIST}/${email ? userId : authUserId}`}
              label={addedBy ?? deletedBy ?? ''}
            />
          );

          switch (actionType) {
            case NotificationActionType.USER_INVITED:
              return (
                <div className='flex flex-wrap items-center'>
                  {renderUser()}
                  &nbsp;has been invited by&nbsp;
                  {renderAddedBy()}
                  {role && (
                    <>
                      &nbsp;as&nbsp;
                      <span>{getUserFullName(role)}</span>
                    </>
                  )}
                </div>
              );

            case NotificationActionType.USER_ADDED_TO_TEAM:
              return (
                <div className='flex flex-wrap items-center'>
                  {renderUser()}
                  &nbsp;is added to&nbsp;
                  {team && (
                    <>
                      team&nbsp;
                      <NotificationLink
                        path={`/${ROUTES.USER_AND_TEAMS.TEAM.LIST}/id/${teamId}`}
                        label={team}
                      />
                    </>
                  )}
                  &nbsp;by&nbsp;
                  {renderAddedBy()}
                </div>
              );

            case NotificationActionType.USER_REMOVED_FORM_TEAM:
              return (
                <div className='flex flex-wrap items-center'>
                  {renderUser()}
                  &nbsp;is removed from&nbsp;
                  {team && (
                    <>
                      team&nbsp;
                      <NotificationLink
                        path={`/${ROUTES.USER_AND_TEAMS.TEAM.LIST}/id/${teamId}`}
                        label={team}
                      />
                    </>
                  )}
                  &nbsp;by&nbsp;
                  {renderAddedBy()}
                </div>
              );

            case NotificationActionType.USER_REGISTRATION_COMPLETED:
              return (
                <div className='flex flex-wrap items-center'>
                  {renderUser()}
                  &nbsp;has completed their registration&nbsp;
                </div>
              );
          }
        }
        break;

      case NotificationType.TEAMS:
        return (
          <div className='flex flex-wrap items-center'>
            Team&nbsp;
            <NotificationLink
              path={`/${ROUTES.USER_AND_TEAMS.TEAM.LIST}/id/${teamId}`}
              label={team ?? teamName ?? 'N/A'}
            />
            &nbsp;has been {actionType.toLowerCase().split('_')[1]} by&nbsp;
            <NotificationLink
              path={`/${ROUTES.USER_AND_TEAMS.USER.LIST}/${authUserId ?? userId}`}
              label={userName ?? deletedBy ?? ''}
            />
          </div>
        );

      case NotificationType.ORDERS:
        return (
          <div className='flex flex-col flex-wrap items-start gap-1'>
            <div className='flex flex-wrap items-center'>
              <NotificationLink
                path={`/${ROUTES.USER_AND_TEAMS.USER.LIST}/${authUserId}`}
                label={orderBy ?? ''}
              />
              &nbsp;has placed&nbsp;
              <NotificationLink
                path={`/${ROUTES.ORDERS.MY_ORDERS}/${orderId}`}
                label={`#${orderCustomId}`}
              />
              &nbsp;order worth&nbsp;
              {formatCompanyPrice(Number(total))}
            </div>

            {paymentMethod === PaymentMethod.CREDIT ? (
              <div className='flex flex-wrap items-center'>
                <NotificationLink
                  path={`/${ROUTES.ORDERS.MY_ORDERS}/${orderId}`}
                  label={`#${orderCustomId}`}
                />
                &nbsp;has been placed on your account&nbsp;
              </div>
            ) : (
              <div className='flex flex-wrap items-center'>
                <NotificationLink
                  path={`/${ROUTES.ORDERS.MY_ORDERS}/${orderId}`}
                  label={`#${orderCustomId}`}
                />
                &nbsp;has been paid via{' '}
                {getUserFullName(getNotificationKey(paymentMethod!))}&nbsp;
              </div>
            )}
          </div>
        );

      case NotificationType.PROMOS:
        return (
          <div className='flex flex-wrap items-center'>
            &nbsp;⚡︎ Flash Sale Alert ⚡︎ upto{' '}
            {getDiscountLabel(Number(discount), discountLabel!)} discount on{' '}
            &nbsp;
            <NotificationLink
              path={`/${ROUTES.MARKETING_RESOURCES.FLASH_PROMOTIONS}/${promoId}`}
              label={name!}
            />
            &nbsp;
          </div>
        );

      case NotificationType.PRODUCTS:
        switch (actionType) {
          case NotificationActionType.PRODUCT_CREATED:
            return (
              <div className='flex flex-col flex-wrap items-start gap-1'>
                &nbsp;New Product Available&nbsp;
                <div className='flex flex-wrap items-center gap-1'>
                  <NotificationLink
                    path={`/${ROUTES.PRODUCT.ALL_PRODUCTS}/${categoryId}/details/${productId}?sub-category=${productTypeId}`}
                    label={`${sku}`}
                  />
                  &nbsp;is available for purchase!&nbsp;
                </div>
              </div>
            );

          default:
            return null;
        }

      case NotificationType.SPECIAL_PRICES:
        return (
          <div className='flex flex-col flex-wrap items-start gap-1'>
            <div>&nbsp;Special offer just for you!&nbsp;</div>
            <div className='ml-1 flex flex-wrap items-center gap-1'>
              {getDiscountLabel(Number(price), discountLabel!)}
              &nbsp;off on&nbsp;
              {categories?.map((category, index) => (
                <span
                  key={category.name}
                  className='flex break-words break-all'
                >
                  {category.products_count}{' '}
                  {category.products_count === 1 ? 'product' : 'products'} from{' '}
                  &nbsp;
                  <NotificationLink
                    path={`/${ROUTES.PRODUCT.ALL_PRODUCTS}/${category.id}`}
                    label={category.name ?? ''}
                    isSpecialPrice={true}
                  />
                  &nbsp;
                  {index < categories.length - 1 && ','}&nbsp;
                </span>
              ))}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className='text-base font-semibold text-gray-800'>
      {getNotificationLabel()}
    </div>
  );
};

export default NotificationLabel;
