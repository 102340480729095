import { useState } from 'react';
import { isPast } from 'date-fns';

import { Badge, Button, Link } from '@/components';
import { MediaType, ROUTES } from '@/utils/constants';
import { formatDate } from '@/utils/helper/datetime.helper';
import { formatCompanyPrice } from '@/utils/helper/country-locale';
import { AsyncLoaderImage } from '@/components/ui/image/AsyncImage';
import { DiscountLabel, STATUS } from '@/utils/constants/promotion.constant';
import { QuickViewModal } from '@/pages/marketing-resources/flash-promotions/quick-view';

interface Media {
  id: string;
  type: string;
  sort: number;
  altText: string | null;
  url: string;
}

export interface Promotion {
  id: string;
  name: string;
  description?: string | null;
  discountLabel: DiscountLabel;
  discount: string;
  startDate: string;
  endDate: string;
  status: STATUS;
  medias: Partial<Media>[];
}

export function PromotionCard({ promotion }: { promotion: Promotion }) {
  const isPromotionActive = !isPast(promotion.endDate);

  const [showQuickView, setShowQuickView] = useState(false);

  const thumbnailImage = promotion.medias.find(
    (item) => item.type === MediaType.THUMBNAIL,
  );

  return (
    <div className='items-center justify-between space-y-2 lg:flex lg:border-b lg:pb-8'>
      <figure className='flex flex-col gap-x-6 gap-y-2 lg:flex-row lg:items-center'>
        <div className='h-[116px] rounded lg:w-[236px]'>
          <AsyncLoaderImage
            className='h-full w-full rounded object-cover'
            src={thumbnailImage?.url}
            alt={thumbnailImage?.altText ?? ''}
          />
        </div>
        <figcaption className='space-y-1'>
          {isPromotionActive ? (
            <Badge
              variant='green'
              className='mb-1'
            >
              {promotion.discountLabel === DiscountLabel.FIXED
                ? `Sale ${formatCompanyPrice(Math.round(Number(promotion.discount)))} off`
                : `Sale up to ${promotion.discount}% off`}
            </Badge>
          ) : (
            <Badge
              variant='red'
              className='mb-1'
            >
              Expired
            </Badge>
          )}
          <p className='text-xl font-semibold text-gray-800'>
            {promotion.name}
          </p>
          <p className='text-base font-medium text-gray-800'>
            <span className='font-bold text-gray-600'>
              {isPromotionActive ? 'Expires' : 'Expired'} in:
            </span>{' '}
            {formatDate(promotion.endDate)}
          </p>
        </figcaption>
      </figure>
      <div className='flex flex-col items-center gap-1 lg:flex-row lg:gap-4'>
        {showQuickView && (
          <QuickViewModal
            promotionId={promotion.id}
            showQuickView={showQuickView}
            setShowQuickView={setShowQuickView}
            isPromotionActive={isPromotionActive}
          />
        )}
        <Button
          onClick={() => setShowQuickView(true)}
          intent={isPromotionActive ? 'outline' : 'solid'}
          className='w-full'
        >
          Quick View
        </Button>
        {isPromotionActive && (
          <Link
            className='w-full'
            to={`/${ROUTES.MARKETING_RESOURCES.FLASH_PROMOTIONS}/${promotion.id}`}
          >
            Shop Products
          </Link>
        )}
      </div>
    </div>
  );
}
