import { Link } from 'react-router-dom';

import { cn } from '@/utils/cn';
import { ROUTES } from '@/utils/constants';
import { useClipboard } from '@/components/hooks';
import { TOrderSchema } from '@/schemas/search/search.schema';
import { COLOUR_CONSTANTS } from '@/utils/constants/colour-constants';
import { formatDateNotification } from '@/utils/helper/datetime.helper';
import { highlightMatchingText } from '@/utils/helper/highlightText.helper';
import { Button, IconsComponent, ImageComponent, Tooltip } from '@/components';

const OrderSearchList = ({
  searchValue,
  data,
}: {
  searchValue: string;
  data: TOrderSchema;
}) => {
  const { copyToClipboard, copied } = useClipboard();
  const handleCopyText = async () => {
    await copyToClipboard(data.customId ?? '');
  };
  return (
    <div className='product-search-list group relative mb-4 flex w-full items-center gap-5 rounded-md bg-white p-5 shadow-sm'>
      <div className='w-[56px]'>
        <ImageComponent
          src={
            data.items[0]?.product?.media?.url ??
            '../src/assets/images/placeholder-image.png'
          }
          alt={data.items[0]?.product?.media?.altText ?? 'Product Image'}
          width={56}
          height={56}
        />
      </div>
      <div className='flex w-[calc(100%_-_56px)] flex-wrap items-center gap-2 lg:justify-between'>
        <div className='flex flex-col gap-1 lg:w-[calc(100%_-_200px)]'>
          <div className='flex items-center justify-start gap-1.5'>
            <span className='text-base text-gray-800'>Order</span>
            <Link
              to={`/${ROUTES.ORDERS.MY_ORDERS}/${data.id}`}
              className='inline-flex w-auto p-0 text-base font-medium text-gray-800 hover:bg-transparent hover:text-blue-600 hover:underline'
            >
              <span>
                #{highlightMatchingText(data.customId ?? '', searchValue)}
              </span>
            </Link>
          </div>
          <div className='flex w-full flex-wrap items-center justify-start gap-3'>
            <span className='text-sm font-medium text-gray-600'>
              {highlightMatchingText(
                data.items[0]?.product?.name ?? '',
                searchValue,
              )}
            </span>
            <span className='text-sm font-medium text-gray-600'>
              Ordered Quantity{' '}
              <span className='text-800'>{data.items[0]?.quantity}</span>
            </span>
            <span className='flex items-center gap-1.5'>
              <span
                className='block h-3 w-3 rounded-full'
                style={{
                  backgroundColor: `#${data.items[0]?.product?.colourHex}`,
                }}
              />
              {data.items[0]?.product.colourName}
            </span>
          </div>
        </div>
        <div className='relative w-full lg:w-auto'>
          <div className='bottom-[-50px] right-0 z-20 transition-all duration-100 group-hover:bottom-0 group-hover:opacity-100 lg:absolute lg:opacity-0'>
            <Tooltip>
              <Tooltip.Trigger>
                <Button
                  variant='red'
                  intent='tertiary'
                  size='icon'
                  className='p-0 text-primary-600'
                  onClick={handleCopyText}
                >
                  <IconsComponent
                    icon='reg-copy'
                    size='sm'
                    fill={COLOUR_CONSTANTS.PRIMARY}
                  />
                  copy id
                </Button>
              </Tooltip.Trigger>
              <Tooltip.Content
                position='top'
                className='w-[110px] text-center text-xs'
              >
                {!copied ? 'Copy Link!' : 'Copied !'}
              </Tooltip.Content>
            </Tooltip>
          </div>

          <div
            className={`${cn(
              'flex flex-wrap items-end space-x-3 text-xs text-gray-600 lg:justify-end',
            )} product-price-button-wrap relative mt-2 transition-all duration-100 lg:mt-0 lg:group-hover:opacity-0`}
          >
            Last Updated{' '}
            <span className='block w-full text-gray-800 lg:ml-1.5 lg:text-right'>
              {data.updatedAt ? formatDateNotification(data.updatedAt) : '-'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSearchList;
