const isBrowser = () => typeof window !== 'undefined';

export const storeLocalStorage = (key: string, value: string) => {
  try {
    if (isBrowser()) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (error) {
    console.error(`Error storing item to localStorage: ${error}`);
  }
};

export const storeSearchQuery = (query: string) => {
  try {
    if (!isBrowser()) return;

    const previousQueries: string[] = JSON.parse(
      localStorage.getItem('searchQueries') ?? '[]',
    ) as string[];

    if (!previousQueries.includes(query)) {
      const updatedQueries = [query, ...previousQueries].slice(0, 5);

      localStorage.setItem('searchQueries', JSON.stringify(updatedQueries));
    }
  } catch (error) {
    console.error(`Error processing search query: ${error}`);
  }
};

export const getStoredSearchQueries = (): string[] => {
  try {
    if (!isBrowser()) return [];

    const storedSearchQueries = localStorage.getItem('searchQueries');
    return storedSearchQueries
      ? (JSON.parse(storedSearchQueries) as string[])
      : [];
  } catch (error) {
    console.error(`Error getting search queries from localStorage: ${error}`);
    return [];
  }
};
