import { z } from 'zod';

import {
  SingleProductDetailDynamicPriceSchema,
  SingleProductDetailSchema,
  SingleProductVariantSchema,
} from '../products';
import { BaseResponseSchema } from '@/schemas/common.schema';
import { PaymentMethod } from '@/utils/constants/payment.constant';
import { AddressSchema } from '@/schemas/createShippingAddress.schema';
import { SingleCarriageDetailsSchema } from '../carriageDetails.schema';
import { ORDER_CONSTANTS_KEYS } from '@/utils/constants/order.constants';

const SingleOrderDetailItemVariantSchema = SingleProductVariantSchema.extend({
  orderItemId: z.string().uuid().nullable().optional(),
  variantId: z.string().uuid().nullable().optional(),
  quantity: z.number().int().positive().nullable().optional(),
  productPrice: z.string().nullable().optional(),
  backorder: z.number().nullable().optional(),
  backorderExpectedAt: z.string().nullable().optional(),
  product: SingleProductDetailSchema.nullable().optional(),
  salePrice: z.string().nullable().optional(),
});

export type TSingleOrderDetailItemVariantSchema = z.infer<
  typeof SingleOrderDetailItemVariantSchema
>;

export const SingleOrderDetailItemSchema = z.object({
  id: z.string().uuid(),
  productId: z.string().uuid(),
  quantity: z.number().int().positive(),
  price: z.string(),
  priceType: z.string(),
  discountLabel: z.string().nullable().optional(),
  discount: z.string().nullable().optional(),
  salePrice: z.string(),
  totalPrice: z.string(),
  variants: z.array(SingleOrderDetailItemVariantSchema),
  product: SingleProductDetailSchema,
  prices: z
    .union([z.array(SingleProductDetailDynamicPriceSchema), z.null()])
    .nullable()
    .optional(),
});

export const SingleOrderDetailBaseSchema = z.object({
  id: z.string().uuid().nullable().optional(),
  companyId: z.string().uuid().nullable().optional(),
  status: z.enum(ORDER_CONSTANTS_KEYS).nullable().optional(),
  items: z.array(SingleOrderDetailItemSchema).nullable().optional(),
  company: z
    .object({
      currency: z.string(),
      rate: z.string(),
      creditPayment: z.boolean(),
      carriageFree: z.boolean(),
    })
    .optional(),
  carriages: z.array(SingleCarriageDetailsSchema).optional().nullable(),
});

const VariantSchema = z.object({
  id: z.string(),
  quantity: z.number().optional(),
  stock: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  size: z.string().optional(),
  variantId: z.string().optional(),
});

const OrderItemVariantSchema = z.object({
  id: z.string(),
  orderId: z.string(),
  orderItemId: z.string(),
  variantId: z.string(),
  quantity: z.number(),
  stock: z.number(),
  backorder: z.number(),
  backorderExpectedAt: z.string().nullable(),
  variants: VariantSchema,
});

const OrderItemSchema = z.object({
  id: z.string(),
  orderId: z.string(),
  productId: z.string(),
  quantity: z.number(),
  price: z.string(),
  priceType: z.string(),
  discountLabel: z.string().nullable(),
  discount: z.string(),
  salePrice: z.string(),
  totalPrice: z.string(),
  product: SingleProductDetailSchema.extend({
    price: z.number(),
  }).omit({
    prices: true,
  }),
  createdAt: z.string(),
  updatedAt: z.string(),
  orderItemVariant: z.array(OrderItemVariantSchema),
});

const UserSchema = z.object({
  id: z.string().optional(),
  email: z.string(),
  avatar: z.string().nullable(),
  lastName: z.string().optional(),
  firstName: z.string().optional(),
});

const OrderDetailSchema = z.object({
  id: z.string(),
  companyId: z.string(),
  userId: z.string(),
  status: z.string(),
  user: UserSchema,
  billingAddress: AddressSchema.nullable(),
  shippingAddress: AddressSchema.nullable(),
  customId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  orderItem: z.array(OrderItemSchema),
  company: z.object({
    name: z.string(),
  }),
  transaction: z.object({
    paymentMethod: z.nativeEnum(PaymentMethod),
  }),
  vatAmount: z.string().nullable(),
  amount: z.string().nullable(),
  totalAmount: z.string().nullable(),
  shippingAmount: z.string().nullable(),
});

export const GetOrderDetailResponseSchema = BaseResponseSchema.extend({
  data: OrderDetailSchema,
  timestamp: z.string(),
});
export const GetOrderDetailRequestSchema = z.string();

export type TSingleOrderItemSchema = z.infer<typeof OrderDetailSchema>;

export type TOrderItemSchema = z.infer<typeof OrderItemSchema>;

export type TOrderItemVariantSchema = z.infer<typeof OrderItemVariantSchema>;

export const ReorderProductRequestSchema = z.string();

export const ReorderProductResponseSchema = BaseResponseSchema;
