import { useEffect, useState } from 'react';
import { Form, useSearchParams } from 'react-router-dom';

import { isEmpty } from '@/utils/helper';
import { EmptyMessage } from '@/components/ui/empty-message';
import { Checkbox, InputSkeletons, Label } from '@/components';
import { HttpMethods, QUERY_CONSTANTS } from '@/utils/constants';
import { ApplyButton, FilterGroup, handleChange } from '../index';
import { ProductFilterKeys, useProductFilterStore } from '@/store';
import { ProductFilterStateKeys } from '@/store/product-filter.store';
import { useFilterSubmit, useGetAllProducts } from '@/components/hooks';

export const ShoeTechnology = () => {
  const {
    selectedOptions,
    setSelectedOptions,
    handleSubmit,
    isButtonDisabled,
    defaultSearchParams: urlParams,
  } = useFilterSubmit(QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_TECHNOLOGY);

  const {
    toggleFilter,
    showShoeTechnology,
    setProductFilter,
    shoeTechnologySelected,
  } = useProductFilterStore();

  const { data: allProductsData, isLoading: isAllProductDataLoading } =
    useGetAllProducts();

  const [searchParams, setSearchParams] = useSearchParams();
  const [disableAnimation, setDisableAnimation] = useState(false);

  const handleShoeTechnologyOptionClick = (id: string) => {
    handleChange(
      id,
      shoeTechnologySelected,
      setSelectedOptions,
      setProductFilter,
      ProductFilterStateKeys.ShoeTechnologyOptionsSelected,
    );
    searchParams.delete(QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_TECHNOLOGY, id);
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  const showApplyButton = () => {
    return (
      !isButtonDisabled &&
      selectedOptions.length === shoeTechnologySelected.length &&
      selectedOptions.length !== urlParams.length
    );
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableAnimation(true);
    try {
      handleSubmit(event);
    } finally {
      setDisableAnimation(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedOptions)) {
      if (!showShoeTechnology) {
        toggleFilter(ProductFilterKeys.ShoeTechnology);
        setProductFilter(
          ProductFilterStateKeys.ShoeTechnologyOptionsSelected,
          selectedOptions,
        );
      }
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (!isEmpty(shoeTechnologySelected)) {
      setSelectedOptions(shoeTechnologySelected);
    }
  }, [searchParams]);

  return (
    <FilterGroup
      title='Technology'
      isActive={showShoeTechnology}
      toggleFilter={() => toggleFilter(ProductFilterKeys.ShoeTechnology)}
    >
      {isAllProductDataLoading ? (
        <InputSkeletons height={36} />
      ) : isEmpty(allProductsData?.filterable?.technologies) ? (
        <EmptyMessage
          message='Shoe technologies not found'
          variant='400'
        />
      ) : (
        <Form
          method={HttpMethods.GET}
          onSubmit={onSubmitHandler}
        >
          <div className='flex flex-col flex-wrap gap-4'>
            {Array.from(
              new Set(
                allProductsData?.filterable?.technologies.map(
                  (tech) => tech.title,
                ),
              ),
            ).map((title) => (
              <div
                className='flex gap-2'
                key={title}
              >
                <Checkbox
                  id={title}
                  name={QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_TECHNOLOGY}
                  variant='blue'
                  value={shoeTechnologySelected
                    .filter((item) => item === title)
                    .toString()}
                  onChange={() => {
                    handleShoeTechnologyOptionClick(title);
                  }}
                  checked={shoeTechnologySelected.includes(title)}
                />
                <Label
                  htmlFor={title}
                  className='font-normal text-gray-800'
                >
                  {title}
                </Label>
              </div>
            ))}
          </div>
          <ApplyButton
            showApplyButton={showApplyButton}
            disableAnimation={disableAnimation}
          />
        </Form>
      )}
    </FilterGroup>
  );
};
