//**Dummy json data for testing */

export const RoleOptions = [
  {
    value: 'ADMIN',
    label: 'Admin',
  },
  {
    value: 'STAFF',
    label: 'Staff',
  },
];

export const StatusOptions = [
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'INACTIVE',
    label: 'Inactive',
  },
  {
    value: 'INVITED',
    label: 'Invite Pending',
  },
];
