import { createPortal } from 'react-dom';
import React, { useRef, useContext, createContext } from 'react';

import { cn } from '@/utils/cn';
import { useOutsideClick } from '@/components/hooks';
import { Button, ButtonProps, IconsComponent } from '@/components';

interface ModalBaseProps extends React.HTMLAttributes<HTMLDivElement> {}

interface ModalProps extends ModalBaseProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalContext = createContext<{
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  modalRef: React.RefObject<HTMLDivElement>;
}>({
  showModal: false,
  setShowModal: () => {},
  modalRef: { current: null },
});

function Modal({ children, showModal, setShowModal, className }: ModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(modalRef, () => {
    setShowModal(false);
  });

  return (
    <ModalContext.Provider value={{ showModal, setShowModal, modalRef }}>
      <div
        className={cn('modal-container', showModal ? 'show' : '', className)}
      >
        {children}
      </div>
    </ModalContext.Provider>
  );
}

function ModalContent({ children, className }: ModalBaseProps) {
  const { showModal, modalRef } = useContext(ModalContext);
  return (
    showModal &&
    createPortal(
      <>
        <div
          className='fixed inset-0 z-[999] flex items-end justify-center overflow-y-auto overflow-x-hidden md:items-center'
          data-cy='modal'
        >
          <div
            ref={modalRef}
            className='modal-wrap relative w-full sm:mx-auto md:my-6 md:w-auto'
          >
            <div
              className={cn(
                'modal-content relative rounded bg-white shadow-lg md:h-auto md:w-[600px] lg:w-full',
                className,
              )}
            >
              {children}
            </div>
          </div>
        </div>
        <div className='fixed inset-0 z-100 bg-black opacity-70' />
      </>,
      document.getElementById('modal') as Element,
    )
  );
}

function ModalCloseButton({
  children,
  intent = 'tertiary',
  variant = 'gray',
  ...props
}: ButtonProps) {
  const { setShowModal } = useContext(ModalContext);

  return (
    <Button
      intent={intent}
      variant={variant}
      onClick={() => setShowModal(false)}
      {...props}
    >
      {children}
    </Button>
  );
}

function ModalTitle({ children, className }: ModalBaseProps) {
  return (
    <div className={cn('flex p-3 sm:p-6', className)}>
      {children}
      <ModalCloseButton
        size='icon'
        className='absolute right-4 top-1 sm:top-3'
      >
        <IconsComponent
          icon='reg-close'
          size='md'
        />
      </ModalCloseButton>
    </div>
  );
}

function ModalBody({ children, className }: ModalBaseProps) {
  return <div className={cn('border-t p-4 sm:p-8', className)}>{children}</div>;
}

function ModalFooter({ children, className }: ModalBaseProps) {
  return (
    <div
      className={cn('flex items-center justify-end border-t p-6', className)}
    >
      {children}
    </div>
  );
}

function ModalToggleButton({ ...props }: ButtonProps) {
  const { setShowModal } = useContext(ModalContext);

  return (
    <Button
      {...props}
      onClick={() => setShowModal((prev) => !prev)}
    />
  );
}

function SheetContent({ children, className }: ModalBaseProps) {
  const { showModal, modalRef } = useContext(ModalContext);

  return createPortal(
    <>
      <div
        ref={modalRef}
        className={cn(
          `${showModal ? 'translate-x-0' : 'translate-x-full'} fixed inset-y-0 right-0 z-100 overflow-y-auto rounded bg-white transition-all duration-500`,
          className,
        )}
        data-cy='sheet'
      >
        {children}
      </div>
      {showModal && <div className='fixed inset-0 z-90 bg-black opacity-70' />}
    </>,
    document.getElementById('modal') as Element,
  );
}

Modal.Body = ModalBody;
Modal.Title = ModalTitle;
Modal.Footer = ModalFooter;
Modal.Content = ModalContent;
Modal.Toggle = ModalToggleButton;
Modal.SheetContent = SheetContent;
Modal.CloseButton = ModalCloseButton;

export { Modal };
