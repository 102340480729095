import { ROUTES } from '@/utils/constants';
import { Link } from '@/components/ui/link';
import useNotificationStore from '@/store/notification.store';

const ViewAll = () => {
  const { setShowNotificationModal, setActiveNotificationTab } =
    useNotificationStore();

  return (
    <div className='row-span-2 flex w-full justify-end border-t border-gray-200 bg-white p-6'>
      <Link
        variant='primary'
        intent='outline'
        to={`${ROUTES.NOTIFICATIONS}?tab=all`}
        size='md'
        className='flex items-center gap-2'
        onClick={() => {
          setShowNotificationModal(false);
          setActiveNotificationTab('page', 'all');
        }}
      >
        View All
      </Link>
    </div>
  );
};

export default ViewAll;
