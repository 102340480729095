import { useEffect } from 'react';

import {
  getNotificationKey,
  transformNotification,
} from '@/utils/helper/notifications.helper';
import { isEmpty } from '@/utils/helper';
import { Button } from '@/components/ui/button';
import useNotificationStore from '@/store/notification.store';
import EmptyNotificationsMessage from './emptyNotificationsMessage';
import { NotificationType } from '@/utils/constants/notification.constants';
import NotificationIcon from '@/components/ui/notifications/notificationIcon';
import NotificationLabel from '@/components/ui/notifications/notificationLabel';
import NotificationStatus from '@/components/ui/notifications/notificationStatus';
import NotificationButtons from '@/components/ui/notifications/notificationButtons';
import { SingleColumnSkeleton } from '@/components/ui/skeleton/SingleColumnSkeleton';
import useGetNotifications from '@/components/hooks/api/notifications/useGetNotifications';
import useMarkNotificationAsRead from '@/components/hooks/api/notifications/useMarkNotificationAsRead';

const UnreadNotifications = () => {
  const { data: unreadNotifications, isLoading: unreadNotificationsLoading } =
    useGetNotifications({
      unread: true,
    });

  const { mutate: markAllNotificationsAsRead } = useMarkNotificationAsRead();

  const { allFormattedModalNotifications, setAllFormattedModalNotifications } =
    useNotificationStore();

  useEffect(() => {
    if (isEmpty(unreadNotifications?.data)) return;

    setAllFormattedModalNotifications(
      transformNotification(unreadNotifications?.data ?? []),
    );
  }, [unreadNotifications]);

  if (unreadNotificationsLoading) {
    return (
      <SingleColumnSkeleton
        noOfCols={3}
        className='px-6'
        height={4}
      />
    );
  }

  if (
    isEmpty(unreadNotifications?.data) ||
    Object.entries(allFormattedModalNotifications).every(([, notifications]) =>
      isEmpty(notifications),
    )
  )
    return <EmptyNotificationsMessage isUnread={true} />;

  return (
    <>
      {Object.keys(allFormattedModalNotifications).map((key) => {
        return (
          <div key={key}>
            <div className='px-6 text-lg font-semibold text-gray-400'>
              {getNotificationKey(key)}
            </div>
            <div className='grid max-h-80 gap-2 overflow-y-auto py-4'>
              {allFormattedModalNotifications[key]
                .filter((notification) => !notification.isNotificationRead)
                .map((notification) => {
                  return (
                    <div
                      key={notification.id}
                      className='flex space-y-2 px-6'
                    >
                      <Button
                        className='flex w-full items-start justify-between px-0 py-3'
                        onClick={() => {
                          if (notification.isNotificationRead) return;

                          markAllNotificationsAsRead(notification.id, {
                            onSuccess: () => {
                              const mutatedNotifications = [
                                ...allFormattedModalNotifications[key],
                              ];
                              const filteredNotifications =
                                mutatedNotifications.filter(
                                  (item) => item.id !== notification.id,
                                );
                              setAllFormattedModalNotifications({
                                ...allFormattedModalNotifications,
                                [key]: filteredNotifications,
                              });
                            },
                          });
                        }}
                        intent={'tertiary'}
                      >
                        <div className='flex gap-2'>
                          <NotificationIcon
                            notificationType={
                              notification.type as unknown as NotificationType
                            }
                          />
                          <div>
                            <NotificationLabel notification={notification} />
                            <NotificationStatus
                              notificationTime={notification.timeAgo ?? ''}
                              notificationType={notification.type}
                            />
                            <NotificationButtons notification={notification} />
                          </div>
                        </div>
                      </Button>
                      {!notification.isNotificationRead && (
                        <div className='mt-2 size-2 rounded-full bg-blue-600' />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default UnreadNotifications;
