import { useState, useEffect } from 'react';

import { useEventListener } from '@/components/hooks';

export function useMediaQuery(mediaQuery: string): boolean {
  const [isMatch, setIsMatch] = useState<boolean>(false);
  const [mediaQueryList, setMediaQueryList] = useState<MediaQueryList | null>(
    null,
  );

  useEffect(() => {
    const list: MediaQueryList = window.matchMedia(mediaQuery);
    setMediaQueryList(list);
    setIsMatch(list.matches);
  }, [mediaQuery]);

  useEventListener(
    'change',
    (e) => {
      const mediaEvent = e as MediaQueryListEvent;
      setIsMatch(mediaEvent.matches);
    },
    mediaQueryList,
  );

  return isMatch;
}

export const useIsDeviceMobileOrLarger = () => {
  const isTabletOrLarger: boolean = useMediaQuery('(min-width: 640px)');

  return isTabletOrLarger;
};

export const useIsDeviceTabletOrLarger = () => {
  const isTabletOrLarger: boolean = useMediaQuery('(min-width: 840px)');

  return isTabletOrLarger;
};

export const useIsDeviceMediumOrLarger = () => {
  const isDeviceMediumOrLarger: boolean = useMediaQuery('(min-width: 1140px)');

  return isDeviceMediumOrLarger;
};

export const useCustomMediaQuery = (height: string | number): boolean => {
  const inputHeight = String(height);

  const isHeightSmallerThanCustomMediaQuery: boolean = useMediaQuery(
    `(max-height: ${inputHeight}px)`,
  );

  return isHeightSmallerThanCustomMediaQuery;
};
