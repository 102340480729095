import { handleApiError } from '@/utils/helper';
import { useGenerate2FA } from '@/components/hooks';
import { TwoFactorAuthEnable } from '../twofa-enable';
import { LoadingContent } from '@/components/ui/loading/Loading';

export function Generate2FAPage() {
  const { data: secret2FAData, isLoading, isError, error } = useGenerate2FA();

  const errorMessage = handleApiError(error);

  if (isLoading) return <LoadingContent />;
  if (isError)
    return (
      <div className='grid place-items-center'>
        <h6>Error on generating QR</h6>
        {errorMessage && (
          <p className='mt-2 text-sm text-gray-600'>{errorMessage}</p>
        )}
      </div>
    );

  return (
    <TwoFactorAuthEnable
      secret={secret2FAData?.secret ?? ''}
      totpUrl={secret2FAData?.totpUrl ?? ''}
    />
  );
}
