import {
  GeneralSearchRequestSchema,
  GeneralSearchResponseSchema,
  OrderSearchResponseSchema,
  ProductSearchResponseSchema,
  TGeneralSearchParams,
  TGeneralSearchRequestSchema,
  TGeneralSearchResponseSchema,
  TOrderSearchResponseSchema,
  TProductSearchResponseSchema,
  TTeamSearchResponseSchema,
  TUserSearchResponseSchema,
  TeamSearchResponseSchema,
  UserSearchResponseSchema,
} from '@/schemas/search/search.schema';
import { API_ENDPOINT, HttpMethods } from '@/utils/constants';
import { api } from '@/utils/helper';
import { toQueryString } from '@/utils/helper/toQueryString.helper';

export const getGeneralSearchData = (params: TGeneralSearchParams = {}) => {
  // Convert params object to query string
  const queryString = toQueryString(params);

  const path = `${API_ENDPOINT.SEARCH.GENERAL_SEARCH}?${queryString}`;

  return api<TGeneralSearchRequestSchema, TGeneralSearchResponseSchema>({
    method: HttpMethods.GET,
    path,
    requestSchema: GeneralSearchRequestSchema,
    responseSchema: GeneralSearchResponseSchema,
  });
};

export const getProductSearchData = (params: TGeneralSearchParams = {}) => {
  // Convert params object to query string
  const queryString = toQueryString(params);

  const path = `${API_ENDPOINT.SEARCH.PRODUCT_SEARCH}?${queryString}`;

  return api<TGeneralSearchRequestSchema, TProductSearchResponseSchema>({
    method: HttpMethods.GET,
    path,
    requestSchema: GeneralSearchRequestSchema,
    responseSchema: ProductSearchResponseSchema,
  });
};

export const getTeamSearchData = (params: TGeneralSearchParams = {}) => {
  // Convert params object to query string
  const queryString = toQueryString(params);

  const path = `${API_ENDPOINT.SEARCH.TEAM_SEARCH}?${queryString}`;

  return api<TGeneralSearchRequestSchema, TTeamSearchResponseSchema>({
    method: HttpMethods.GET,
    path,
    requestSchema: GeneralSearchRequestSchema,
    responseSchema: TeamSearchResponseSchema,
  });
};

export const getUserSearchData = (params: TGeneralSearchParams = {}) => {
  // Convert params object to query string
  const queryString = toQueryString(params);

  const path = `${API_ENDPOINT.SEARCH.USER_SEARCH}?${queryString}`;

  return api<TGeneralSearchRequestSchema, TUserSearchResponseSchema>({
    method: HttpMethods.GET,
    path,
    requestSchema: GeneralSearchRequestSchema,
    responseSchema: UserSearchResponseSchema,
  });
};

export const getOrderSearchData = (params: TGeneralSearchParams = {}) => {
  // Convert params object to query string
  const queryString = toQueryString(params);

  const path = `${API_ENDPOINT.SEARCH.ORDER_SEARCH}?${queryString}`;

  return api<TGeneralSearchRequestSchema, TOrderSearchResponseSchema>({
    method: HttpMethods.GET,
    path,
    requestSchema: GeneralSearchRequestSchema,
    responseSchema: OrderSearchResponseSchema,
    type: 'protected',
  });
};

export const GeneralSearchAPI = {
  getGeneralSearchData,
  getProductSearchData,
  getUserSearchData,
  getOrderSearchData,
  getTeamSearchData,
};
