export const highlightMatchingText = (
  text: string,
  query: string | null | undefined,
) => {
  if (!query || !text) return text;

  // Ensure query is a string
  const safeQuery = String(query);

  // Escape special characters in the query to use in regex
  const escapedQuery = safeQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // Create a case-insensitive regex to match the query
  const regex = new RegExp(`(${escapedQuery})`, 'gi');

  // Split the text by matches and map them to JSX elements
  const parts = text.split(regex);

  return parts.map((part, index) => {
    // Check if this part matches the query (case-insensitive)
    if (part.toLowerCase() === safeQuery.toLowerCase()) {
      return (
        <span
          key={index}
          className='highlight font-bold text-gray-950'
        >
          {part}
        </span>
      );
    }
    return part;
  });
};
