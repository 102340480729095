import { Button, IconsComponent } from '@/components';
import {
  useMediaQuery,
  useOutsideClick,
  useURLSearchParams,
} from '@/components/hooks';
import { useGetGeneralSearch } from '@/components/hooks/api/search/useSearch';
import { cn } from '@/utils/cn';
import { ROUTES } from '@/utils/constants';
import { COLOUR_CONSTANTS } from '@/utils/constants/colour-constants';
import { debounce } from '@/utils/helper';
import {
  getStoredSearchQueries,
  storeSearchQuery,
} from '@/utils/helper/searchStorage.helper';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ItemHeader } from '../ItemHeader';
import ProductSearchList from '../ProductSearchList';
import TeamSearchList from '../TeamSearchList';
import UserSearchList from '../UserSearchList';
import OrderSearchList from '../OrderSearchList';
import {
  TSearch,
  TSearchInput,
  searchInput,
} from '@/schemas/search/search.schema';
import { NoSearchResultFound } from '../NoSearchResultFound';
import { searchValueCleanup } from '../../../../utils/helper/searchValueCleanup';

const HeaderSearchBox = () => {
  const searchRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchlimitStatusRef = useRef<HTMLInputElement>(null);
  const searchRecentContainerRef = useRef<HTMLDivElement>(null);
  const [searchlimitStatus, setSearchLimitStatus] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [recentSearch, setRecentSearch] = useState(false);

  const isNotSmallDevice: boolean = useMediaQuery('(min-width: 640px)');
  const [openSearchMbl, setOpenSearchMbl] = useState(false);

  const searchMethod = useForm<TSearchInput>({
    mode: 'onChange',
    resolver: zodResolver(searchInput),
  });

  const { watch, setValue } = searchMethod;
  const searchValue = watch('search');

  const handleSearch = debounce((query: TSearch) => {
    setValue('search', query!);
  }, 500);

  useEffect(() => {
    const isTyping = Boolean(searchValue);
    setSearchLimitStatus(isTyping && searchValue.length < 3);
    setShowSearch(isTyping && searchValue.length >= 3);
  }, [searchValue]);

  const { data: generalSearch, isLoading } = useGetGeneralSearch(
    {
      q: searchValue && searchValue.trim().length >= 3 ? searchValue : '',
    },
    'header-search',
    {
      refetchOnWindowFocus: false,
    },
  );

  const { searchValue: q } = useURLSearchParams('q');

  const storedSearchQueries = getStoredSearchQueries();

  const handleSeeAllResults = (e: React.MouseEvent) => {
    e.preventDefault();
    if (searchValue) {
      storeSearchQuery(searchValueCleanup(searchValue));
    }
    window.location.href = `/${ROUTES.SEARCH}?q=${searchValueCleanup(searchValue)}`;
  };

  const handleRecentResults = (e: React.MouseEvent, item: string) => {
    // Use window.location.href to force a full page reload
    window.location.href = `/${ROUTES.SEARCH}?q=${item}`;
    e.preventDefault();
  };

  useEffect(() => {
    const handleShortcut = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'k') {
        event.preventDefault();
        event.stopPropagation();
        searchInputRef.current?.focus();
        if (storedSearchQueries.length > 0) {
          setRecentSearch(true);
        }
      }
    };
    document.addEventListener('keydown', handleShortcut);

    return () => {
      document.removeEventListener('keydown', handleShortcut);
    };
  }, [storedSearchQueries]);

  const handleClearSearch = () => {
    setRecentSearch(false);
    setShowSearch(false);
    setSearchLimitStatus(false);
    setValue('search', '');

    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  };
  useOutsideClick(searchRecentContainerRef, () => setRecentSearch(false));
  useOutsideClick(searchlimitStatusRef, () => setRecentSearch(false));

  const productSearch = generalSearch?.data.products;
  const teamSearch = generalSearch?.data.teams;
  const userSearch = generalSearch?.data.users;
  const orderSearch = generalSearch?.data.orders;

  const meta = generalSearch?.meta;
  const totalSearchItem =
    (meta?.product.count ?? 0) +
    (meta?.order.count ?? 0) +
    (meta?.team.count ?? 0) +
    (meta?.user.count ?? 0);

  return (
    <div className='search-container w-full sm:relative'>
      {!isNotSmallDevice && (
        <Button
          intent='tertiary'
          size='xs'
          className='ml-5 px-0'
          onClick={() => setOpenSearchMbl(!openSearchMbl)}
        >
          <IconsComponent
            icon='reg-search'
            fill='#4B5563'
          />
        </Button>
      )}

      {(isNotSmallDevice || openSearchMbl) && (
        <div
          className={`header-search-main-wrap ${openSearchMbl ? 'absolute left-0 top-full block w-full rounded-none' : ''}`}
        >
          <FormProvider {...searchMethod}>
            <div className='relative flex w-full items-center'>
              <div
                className={cn(
                  'absolute top-1/2 -translate-y-1/2 transform',
                  'left-3',
                )}
              >
                <IconsComponent
                  icon='reg-search'
                  fill={COLOUR_CONSTANTS.GRAY_600}
                  size='base'
                />
              </div>
              {!recentSearch && !showSearch && (
                <div className='meta-key-icon absolute right-0 top-1/2 hidden -translate-y-1/2 transform items-center gap-1.5 pr-2 lg:flex'>
                  <span className='rounded-base flex h-6 w-8 items-center justify-center bg-gray-100'>
                    <IconsComponent
                      icon='reg-ctrl'
                      fill={COLOUR_CONSTANTS.GRAY_600}
                      size='sm'
                    />
                  </span>
                  <span className='rounded-base flex h-6 w-8 items-center justify-center bg-gray-100 text-sm font-bold text-gray-600'>
                    K
                  </span>
                </div>
              )}

              <input
                autoComplete='off'
                ref={searchInputRef}
                onChange={(e) => handleSearch(e.target.value)}
                onClick={() => setRecentSearch(true)}
                type='search'
                className={cn(
                  'header-search-input border-grey-100 text-grey-950 placeholder:text-grey-400 hover:border-grey-300 disabled:bg-grey-50 disabled:text-grey-300 disabled:placeholder:text-grey-300 disabled:hover:border-grey-100 w-full rounded-md border px-4 py-2 pl-12 text-base font-semibold leading-6 outline-none duration-300 focus:border-blue-600 disabled:cursor-not-allowed',
                  (showSearch || recentSearch) && '!border-blue-600',
                )}
                name='search'
                placeholder='Search Anything...'
                style={{ transition: 'width 0.3s ease-in-out' }}
                data-cy='search-input'
                defaultValue={searchValue || q}
              />
              {(showSearch || recentSearch) && (
                <Button
                  type='button'
                  variant={'blue'}
                  intent={'tertiary'}
                  className='absolute right-3 top-1/2 inline-flex h-5 w-5 -translate-y-1/2 transform items-center justify-center rounded-full bg-gray-400 p-0'
                  onClick={handleClearSearch}
                >
                  <IconsComponent
                    icon='reg-close'
                    fill={COLOUR_CONSTANTS.WHITE}
                    size='xs'
                  />
                </Button>
              )}
            </div>
          </FormProvider>
        </div>
      )}

      {storedSearchQueries.length > 0 &&
        recentSearch &&
        !showSearch &&
        !searchlimitStatus && (
          <div
            className='absolute left-0 top-[calc(100%+42px)] z-50 w-full overflow-hidden rounded-md bg-white p-5 shadow-lg sm:top-full sm:mt-1'
            ref={searchRecentContainerRef}
          >
            <div className='relative'>
              <h3 className='mb-2 text-sm font-bold text-gray-400'>
                Recent Searches
              </h3>
              {storedSearchQueries.map((item: string, index: number) => (
                <Button
                  key={index}
                  onClick={(e) => handleRecentResults(e, item)}
                  variant={'gray'}
                  intent={'tertiary'}
                  className='mb-2 flex w-full cursor-pointer items-center justify-start gap-3 px-0 py-2 text-left text-base capitalize text-gray-950 last:mb-0'
                >
                  <IconsComponent
                    icon='reg-trend-up'
                    size='sm'
                    fill={COLOUR_CONSTANTS.GRAY_600}
                  />
                  {item}
                </Button>
              ))}
            </div>
          </div>
        )}
      {showSearch && searchValue && (
        <div
          className='absolute left-0 top-[calc(100%+42px)] z-50 w-full overflow-hidden rounded-md bg-white shadow-lg sm:top-full sm:mt-1'
          ref={searchRef}
        >
          {isLoading ? (
            <div className='p-4 text-gray-500'>Loading...</div>
          ) : (
            <>
              <div className='relative z-50 max-h-[400px] overflow-y-auto bg-white p-4'>
                {generalSearch?.data ? (
                  <>
                    {totalSearchItem === 0 ? (
                      <NoSearchResultFound
                        searchValue={searchValue}
                        buttonEnabled={false}
                        className='header-search-no-result p-0'
                      />
                    ) : (
                      <div className='search-results'>
                        {productSearch && productSearch.length > 0 && (
                          <div>
                            {ItemHeader('Products', meta?.product.count ?? 0)}
                            {productSearch.map((product) => (
                              <ProductSearchList
                                data={product!}
                                searchValue={searchValue}
                                key={product?.id}
                              />
                            ))}
                          </div>
                        )}
                        {orderSearch && orderSearch.length > 0 && (
                          <div>
                            {ItemHeader('Orders', meta?.order.count ?? 0)}
                            {orderSearch.map((order) => (
                              <OrderSearchList
                                data={order!}
                                searchValue={searchValue}
                                key={order?.id}
                              />
                            ))}
                          </div>
                        )}
                        {userSearch && userSearch.length > 0 && (
                          <div>
                            {ItemHeader('Users', meta?.user.count ?? 0)}
                            {userSearch.map((user) => (
                              <UserSearchList
                                data={user!}
                                searchValue={searchValue}
                                key={user?.id}
                              />
                            ))}
                          </div>
                        )}
                        {teamSearch && teamSearch.length > 0 && (
                          <div>
                            {ItemHeader('Teams', meta?.team.count ?? 0)}
                            {teamSearch.map((team) => (
                              <TeamSearchList
                                data={team!}
                                searchValue={searchValue}
                                key={team?.id}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <NoSearchResultFound
                    searchValue={searchValue}
                    buttonEnabled={false}
                    className='header-search-no-result p-0'
                  />
                )}
              </div>

              {totalSearchItem > 0 && (
                <div className='relative z-50 w-full border-t border-t-gray-200 bg-white p-4'>
                  <Link
                    to={`/${ROUTES.SEARCH}?q=${searchValue}`}
                    className='inline-flex items-center gap-1.5 text-base font-bold capitalize text-primary-600'
                    onClick={handleSeeAllResults}
                  >
                    View all results
                    <IconsComponent
                      icon='reg-arrow-right'
                      fill={COLOUR_CONSTANTS.PRIMARY}
                      size='sm'
                    />
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      )}

      {searchlimitStatus && (
        <div
          className='absolute left-0 top-[calc(100%+42px)] z-50 w-full overflow-hidden rounded-md bg-white p-5 shadow-lg sm:top-full sm:mt-1'
          ref={searchlimitStatusRef}
        >
          Type some more...
        </div>
      )}
    </div>
  );
};

export default HeaderSearchBox;
