import { format } from 'date-fns';
import { useUserStore } from '@/store/user-store-new';

/**
 * @description Converts a 12-hour time format string to a 24-hour time format string.
 */
export function formatTo24Hour(time: string): string {
  const [hourMin, period] = time.split(' ');
  if (!hourMin || !period) throw new Error('Invalid time format');

  const [hourStr, minuteStr] = hourMin.split(':');
  const hour = Number(hourStr);
  const minute = Number(minuteStr);

  if (isNaN(hour) || isNaN(minute)) throw new Error('Invalid time format');

  const isPM = period.toLowerCase() === 'pm';
  const formattedHour =
    isPM && hour !== 12 ? hour + 12 : !isPM && hour === 12 ? 0 : hour;

  return `${String(formattedHour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
}

/**-
 * @description Converts a 24-hour time format string to a 12-hour time format string.
 */
export function formatTo12Hour(time: string): string {
  const [hourStr, minuteStr] = time.split(':');
  const hour = Number(hourStr);
  const minute = Number(minuteStr);

  if (isNaN(hour) || isNaN(minute)) throw new Error('Invalid time format');

  const period = hour >= 12 ? 'PM' : 'AM';
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12;

  return `${String(formattedHour).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${period}`;
}

/**
 * @description Formats a time string based on the user's system default time format.
 */
export function formatTime(time: string): string {
  const timeFormat = useUserStore.getState().systemDefault?.timeFormat;
  return timeFormat === 'HOUR_12' ? formatTo12Hour(time) : formatTo24Hour(time);
}

/**
 * @description Formats a date string based on the user's system default date format.
 */
export function formatDate(
  date: string | Date | undefined,
  isChart = false,
): string {
  if (!date) return 'N/A';

  const dateFormat = useUserStore.getState().systemDefault?.dateFormat;

  switch (dateFormat) {
    case 'MM_DD_YYYY':
      return format(date, isChart ? 'MM/dd' : 'MM/dd/yyyy');
    case 'DD_MM_YYYY':
      return format(date, isChart ? 'dd/MM' : 'dd/MM/yyyy');
    case 'YYYY_MM_DD':
      return format(date, isChart ? 'MM/dd' : 'yyyy/MM/dd');
    case 'DD_MMMM_YYYY':
      return format(date, isChart ? 'dd MMMM' : 'dd MMMM, yyyy');
    default:
      return format(date, isChart ? 'MM-dd' : 'MM-dd-yyyy');
  }
}

/**
 * @description Formats a date string to 'MM/dd/yyyy' format.
 */
export function formatDateToMonthDayYear(date: string | Date) {
  return format(new Date(date), 'MM/dd/yyyy');
}

/**
 * @description Formats a date string to 'yyyy-MM-dd' format.
 */
export function formatDateToYearMonthDay(date: string | Date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

/**
 * Calculates the time difference between the current time and a given date.
 * Returns a human-readable string representing the time difference.
 *
 * @param createdAt - The date and time to compare against the current time. Can be a string or a Date object.
 * @returns A string representing the time difference in minutes or hours, or 'Just now' if the difference is less than a minute.
 */
export const getTimeDifference = (createdAt: string | Date): string => {
  const now = new Date().getTime();
  const created = new Date(createdAt).getTime();
  const diffInMs = now - created;
  const diffInMinutes = Math.floor(diffInMs / 1000 / 60);

  if (diffInMinutes < 1) {
    return 'Just now';
  } else if (diffInMinutes < 50) {
    return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
  } else {
    const diffInHours = Math.max(1, Math.floor(diffInMinutes / 60));
    return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
  }
};

export const formatDateNotification = (date: string) => {
  return format(new Date(date), 'MMM d, yyyy');
};

/**
 * Extracts the date part (YYYY-MM-DD) from a given date input while ignoring the time component.
 *
 * @param {string | Date} dateInput - The input date, which can be a string (ISO format or YYYY-MM-DD) or a Date object.
 * @returns {string} The extracted date in `YYYY-MM-DD` format.
 * @throws {Error} Throws an error if the input is an invalid date.
 *
 * @example
 * getDateOnly("2025-03-31T23:59:59.999Z"); // Returns "2025-03-31"
 * getDateOnly("2025-03-31"); // Returns "2025-03-31"
 * getDateOnly(new Date("2025-03-31T10:15:30Z")); // Returns "2025-03-31"
 * getDateOnly(new Date()); // Returns today's date in YYYY-MM-DD format
 */
export const getDateOnly = (dateInput: string | Date): string => {
  const date = new Date(dateInput);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid date input');
  }

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};
