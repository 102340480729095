import React from 'react';
import { ChartOptions } from 'chart.js';
import ChartComponent, { ChartDataItem } from '@/components/ui/chart/Chart';
import { cn } from '@/utils/cn';

export function OrderCard({
  title,
  amount,
  description,
  children,
  dataChart,
}: {
  title: string;
  amount: number | string;
  description: string;
  children?: React.ReactNode;
  dataChart?: ChartDataItem[];
}) {
  return (
    <div className={`${dataChart && 'flex gap-4'} rounded bg-white px-4 py-3`}>
      <div>
        <p className='text-xs font-semibold uppercase text-gray-400'>{title}</p>
        <p className='text-xl font-extrabold text-gray-800 sm:text-3xl'>
          {amount}
        </p>
        <div className='flex items-center space-x-1 pt-5'>
          {children}
          <p className='text-sm text-gray-500'>{description}</p>
        </div>
      </div>
      {dataChart && dataChart.length > 0 && (
        <div className='flex-shrink-0'>
          <ChartComponent
            bars={1}
            showShadow={true}
            items={dataChart}
            showScales={false}
            color='blue'
            displayLegend={false}
          />
        </div>
      )}
    </div>
  );
}

interface OrderCardProps {
  title: string;
  amount: number | string;
  description: string;
  children?: React.ReactNode;
  dataChart?: ChartDataItem[];
  labels: string[];
}

export function OrderCardChart({
  title,
  amount,
  description,
  children,
  dataChart,
  labels,
}: OrderCardProps) {
  const generateChartOptions = React.useMemo(() => {
    return {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            callback: function (index: number) {
              if (index === 0 || index === labels.length - 1) {
                return labels[index];
              }
              return '';
            },
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        y: {
          title: {
            display: false,
          },
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
      },
    };
  }, [labels]);

  const hasEnoughDataAvailable = (dataChart?.[0]?.data?.length ?? 0) > 1;

  return (
    <div
      className={cn(
        'flex-1 flex-wrap justify-between rounded bg-white px-4 py-3 xl:flex-nowrap',
        hasEnoughDataAvailable ? 'flex gap-4' : 'xl:w-[496px]',
      )}
    >
      <div>
        <p className='text-sm font-semibold capitalize text-gray-400'>
          {title}
        </p>
        <p className='text-xl font-extrabold text-gray-800 sm:text-3xl'>
          {amount}
        </p>
        <div className='flex items-center space-x-1 pt-5'>
          {children}
          <p className='whitespace-nowrap text-sm text-gray-500'>
            {description}
          </p>
        </div>
      </div>
      {dataChart && hasEnoughDataAvailable && (
        <div className='flex-shrink-0'>
          <ChartComponent
            bars={1}
            showShadow={true}
            items={dataChart}
            showScales={true}
            color='blue'
            displayLegend={false}
            labels={labels}
            options={generateChartOptions as ChartOptions<'bar' | 'line'>}
          />
        </div>
      )}
    </div>
  );
}
