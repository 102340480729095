import {
  TGeneralSearchParams,
  TGeneralSearchResponseSchema,
  TOrderSearchResponseSchema,
  TProductSearchResponseSchema,
  TTeamSearchResponseSchema,
  TUserSearchResponseSchema,
} from '@/schemas/search/search.schema';
import { GeneralSearchAPI } from '@/services/api/search';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

export function useGetGeneralSearch(
  params: TGeneralSearchParams,
  customQueryKey?: string,
  options?: Partial<UseQueryOptions<TGeneralSearchResponseSchema, unknown>>,
) {
  return useQuery({
    queryKey: [customQueryKey ?? 'general-search', params],
    queryFn: () => GeneralSearchAPI.getGeneralSearchData(params)(),
    refetchOnReconnect: true,
    enabled: !!params.q && params.q.length >= 3,
    ...options,
  });
}

export function useGetProductSearch(
  params: TGeneralSearchParams,
  options?: Partial<UseQueryOptions<TProductSearchResponseSchema, unknown>>,
) {
  return useQuery({
    queryKey: ['product-search'],
    queryFn: () => GeneralSearchAPI.getProductSearchData(params)(),
    refetchOnReconnect: true,
    enabled: !!params.q && params.q.length >= 3,
    ...options,
  });
}

export function useGetTeamSearch(
  params: TGeneralSearchParams,
  options?: Partial<UseQueryOptions<TTeamSearchResponseSchema, unknown>>,
) {
  return useQuery({
    queryKey: ['team-search'],
    queryFn: () => GeneralSearchAPI.getTeamSearchData(params)(),
    refetchOnReconnect: true,
    enabled: !!params.q && params.q.length >= 3,
    ...options,
  });
}

export function useGetUserSearch(
  params: TGeneralSearchParams,
  options?: Partial<UseQueryOptions<TUserSearchResponseSchema, unknown>>,
) {
  return useQuery({
    queryKey: ['user-search'],
    queryFn: () => GeneralSearchAPI.getUserSearchData(params)(),
    refetchOnReconnect: true,
    enabled: !!params.q && params.q.length >= 3,
    ...options,
  });
}

export function useGetOrderSearch(
  params: TGeneralSearchParams,
  options?: Partial<UseQueryOptions<TOrderSearchResponseSchema, unknown>>,
) {
  return useQuery({
    queryKey: ['order-search'],
    queryFn: () => GeneralSearchAPI.getOrderSearchData(params)(),
    refetchOnReconnect: true,
    enabled: !!params.q && params.q.length >= 3,
    ...options,
  });
}
