export const COLOUR_CONSTANTS = {
  PRIMARY: '#6E2534',
  GREEN: '#15803D',
  RED: '#B91C1C',
  BLACK: '#000000',
  GRAY_400: '#9CA3AF',
  GRAY_600: '#4B5563',
  BLUE: '#3B82F6',
  LIGHT_BLUE: '#93C5FD',
  LIGHT_GRAY: '#E5E7EB',
  DARK_GRAY: '#1F2937',
  LIGHT_RED: '#F2E4EC',
  DARK_RED: '#C997AE',
  WHITE: '#FFFFFF',
};
