import EmptyNotificationSVG from '@/assets/images/illustration/emptyNotification.svg';

const EmptyNotificationsMessage = ({
  isUnread = false,
}: {
  isUnread?: boolean;
}) => {
  return (
    <div className='flex h-full flex-col items-center justify-center gap-1 px-6'>
      <img
        src={EmptyNotificationSVG}
        alt='empty notification illustration'
        className='mb-12'
      />
      <h4 className='text-3xl'>You&apos;re all caught up!</h4>
      <p className='text-base font-normal text-gray-800'>
        {isUnread
          ? 'You have no unread notifications'
          : 'Your notifications activity will be shown here.'}
      </p>
    </div>
  );
};

export default EmptyNotificationsMessage;
