import axios, { CreateAxiosDefaults, AxiosError } from 'axios';

import { clearLocalStorage } from '@/utils/helper';
import { useUserStore } from '@/store/user-store-new';
import { fingerprintGenerator } from './fingerprintGenerator';
import { ROUTES, V12_FINGERPRINT_KEY } from '@/utils/constants';

const baseConfig: CreateAxiosDefaults = {
  baseURL: import.meta.env.VITE_V12_BASE_URL,
  withCredentials: true,
};

// Instances of Axios
export const instanceWithoutInterceptor = axios.create(baseConfig);
export const instanceWithInterceptor = axios.create(baseConfig);

// Add a request interceptor
instanceWithInterceptor.interceptors.request.use(
  async (config) => {
    const accessToken = useUserStore.getState().auth?.token;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    try {
      const fingerprint = await fingerprintGenerator.getFingerprint();
      config.headers[V12_FINGERPRINT_KEY] = fingerprint;
    } catch (err) {
      console.warn('Could not attach fingerprint:', err);
    }

    return config;
  },
  (error) => {
    console.error('❌ Request Error:', error);
    return Promise.reject(new Error('something bad happened'));
  },
);

// Add a response interceptor
instanceWithInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response) {
      const status = error.response.status;
      const message =
        (error.response.data as { message?: string }).message ??
        'An error occurred';

      switch (status) {
        case 400:
          console.error('⚠️ Bad Request:', message);
          break;
        case 401:
          console.error('⚠️ Unauthorized:', message);
          // Check if the current URL already contains 'login' to avoid infinite loop
          if (!window.location.pathname.includes('/login')) {
            clearLocalStorage();
            window.location.href = `/${ROUTES.LOGIN}?status=unauthorized`;
          }
          break;
        case 403:
          console.error('⚠️ Forbidden:', message);
          break;
        case 404:
          console.error('⚠️ Not Found:', message);
          break;
        case 500:
          console.error('⚠️ Internal Server Error:', message);
          break;
        default:
          console.error('⚠️ Unexpected Error:', message);
          return Promise.reject(error);
      }
    } else if (error.request) {
      console.error('⚠️ No Response Received:', error.message);
    } else {
      console.error('⚠️ Error:', error.message);
    }
    return Promise.reject(error);
  },
);

// Add fingerprint interceptor
instanceWithoutInterceptor.interceptors.request.use(
  async (config) => {
    try {
      const fingerprint = await fingerprintGenerator.getFingerprint();
      config.headers[V12_FINGERPRINT_KEY] = fingerprint;
    } catch (err) {
      console.warn('Could not attach fingerprint:', err);
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);
