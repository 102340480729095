import { createContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  Can,
  handleApiError,
  PERMISSIONS,
  RESTRICTED_SECTIONS,
} from '@/utils/helper';
import { cn } from '@/utils/cn';
import { Button, Modal } from '@/components';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';
import { useSendInvitation } from '@/components/hooks/api/users';
import IconsComponent from '@/components/ui/icons/IconsComponent';
import { useAlert, useCustomMediaQuery } from '@/components/hooks';
import { CreateUser } from '@/pages/users-and-teams/users/create-user/CreateUserColumn';
import { CreateUserForm } from '@/pages/users-and-teams/users/create-user/CreateUserForm';
import { CreateUserTable } from '@/pages/users-and-teams/users/create-user/CreateUserTable';

export const CreateUserTableContext = createContext<{
  createUserList: CreateUser[];
  setCreateUserList: React.Dispatch<React.SetStateAction<CreateUser[]>>;
}>({
  createUserList: [],
  setCreateUserList: () => {},
});

/**
 * @description CreateUserModal component
 */
export function CreateUserModal() {
  const isHeightSmallerThan350px = useCustomMediaQuery(350);
  const isHeightSmallerThan400px = useCustomMediaQuery(400);

  const [createUserList, setCreateUserList] = useState<CreateUser[]>([]);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();

  const { isPending, mutate } = useSendInvitation();

  const handleSendInvitation = () => {
    const createUserListPayload = createUserList.map((user) => ({
      ...user,
      teams: user.teams?.map(({ value }) => value),
    }));

    mutate(createUserListPayload, {
      onSuccess: () => {
        setCreateUserList([]);
        showAlert('Invitation sent successfully', 'success');
        queryClient.invalidateQueries({ queryKey: [TANSTACK_QUERY_KEY.USER] });
      },
      onError: (error) => {
        showAlert(handleApiError(error), 'error');
      },
      onSettled: () => {
        setShowCreateUserModal(false);
      },
    });
  };

  return (
    <Modal
      showModal={showCreateUserModal}
      setShowModal={setShowCreateUserModal}
      className='w-full'
    >
      <Can
        I={PERMISSIONS.CREATE}
        a={RESTRICTED_SECTIONS.CREATE_EDIT_USERS}
      >
        <Modal.Toggle className='w-full'>
          <IconsComponent
            size='md'
            icon='reg-add'
            fill='white'
          />
          Create User
        </Modal.Toggle>
      </Can>
      <Modal.Content className='max-w-[860px]'>
        <Modal.Title>
          <h6>Create a User</h6>
        </Modal.Title>
        <Modal.Body
          className={cn(
            'space-y-0 sm:space-y-4',
            (isHeightSmallerThan350px || isHeightSmallerThan400px) &&
              'overflow-y-auto',
            isHeightSmallerThan350px
              ? 'h-[155px]'
              : isHeightSmallerThan400px
                ? 'h-[255px]'
                : '',
          )}
        >
          <CreateUserTableContext.Provider
            value={{ createUserList, setCreateUserList }}
          >
            <CreateUserForm setShowCreateUserModal={setShowCreateUserModal} />
            <CreateUserTable />
          </CreateUserTableContext.Provider>
        </Modal.Body>
        <Modal.Footer className='justify-between'>
          <div className='flex items-center'>
            <IconsComponent
              size='sm'
              icon='reg-info'
              fill='#4b5563 '
            />
            <p className='ml-1 text-gray-600'>Learn how to invite people</p>
          </div>
          {createUserList.length > 0 && (
            <Button
              disabled={isPending}
              onClick={handleSendInvitation}
            >
              Send Invitation
            </Button>
          )}
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
