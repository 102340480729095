import { z } from 'zod';

import {
  SYSTEM_DATE_FORMATS,
  SYSTEM_TIME_FORMATS,
} from '@/utils/constants/general.constant';

//Time format
export const timeFormatEnum = z.enum(SYSTEM_TIME_FORMATS);

export type TTimeFormat = z.infer<typeof timeFormatEnum>;

export const dateEnum = z.enum(SYSTEM_DATE_FORMATS);
export type TDate = z.infer<typeof dateEnum>;

// Date format
export const DateFormatSchema = z.object({
  dateFormat: dateEnum,
});

export type TDateFormat = z.infer<typeof DateFormatSchema>;

// Shoe size
export const shoeSizeType = z.enum(['UK', 'US', 'EU']);
export type TShoeSize = z.infer<typeof shoeSizeType>;

export const ShoeSizeFormat = z.object({
  shoeSizeType: shoeSizeType,
});
export type TShoeSizeFormat = z.infer<typeof ShoeSizeFormat>;
