import { useEffect } from 'react';

import {
  getNotificationKey,
  transformNotification,
} from '@/utils/helper/notifications.helper';
import { Button } from '@/components/ui/button';
import { isEmpty } from '@/utils/helper/general.helper';
import useNotificationStore from '@/store/notification.store';
import EmptyNotificationsMessage from './emptyNotificationsMessage';
import { NotificationType } from '@/utils/constants/notification.constants';
import NotificationIcon from '@/components/ui/notifications/notificationIcon';
import NotificationLabel from '@/components/ui/notifications/notificationLabel';
import NotificationStatus from '@/components/ui/notifications/notificationStatus';
import NotificationButtons from '@/components/ui/notifications/notificationButtons';
import { SingleColumnSkeleton } from '@/components/ui/skeleton/SingleColumnSkeleton';
import useGetNotifications from '@/components/hooks/api/notifications/useGetNotifications';
import useMarkNotificationAsRead from '@/components/hooks/api/notifications/useMarkNotificationAsRead';

const AllNotifications = () => {
  const { data: allNotifications, isLoading: allNotificationsLoading } =
    useGetNotifications({});
  const { mutate: markAllNotificationsAsRead } = useMarkNotificationAsRead();

  const { allFormattedModalNotifications, setAllFormattedModalNotifications } =
    useNotificationStore();

  useEffect(() => {
    if (isEmpty(allNotifications?.data)) return;

    setAllFormattedModalNotifications(
      transformNotification(allNotifications?.data ?? []),
    );
  }, [allNotifications]);

  if (allNotificationsLoading) {
    return (
      <SingleColumnSkeleton
        noOfCols={3}
        className='px-6'
        height={4}
      />
    );
  }

  if (
    Object.entries(allFormattedModalNotifications).every(
      ([, unreadNotifications]) => isEmpty(unreadNotifications),
    )
  ) {
    return <EmptyNotificationsMessage />;
  }

  return (
    <>
      {Object.keys(allFormattedModalNotifications).map((key) => {
        return (
          <div key={key}>
            <div className='px-6 text-lg font-semibold text-gray-400'>
              {getNotificationKey(key)}
            </div>
            <div className='grid gap-2 py-4'>
              {allFormattedModalNotifications[key].map((notification) => {
                return (
                  <div
                    key={notification.id}
                    className='flex space-y-2 px-6'
                  >
                    <Button
                      className='flex w-full items-start justify-between px-0 py-3'
                      onClick={() => {
                        if (notification.isNotificationRead) return;

                        markAllNotificationsAsRead(notification.id, {
                          onSuccess: () => {
                            const updatedNotifications =
                              allFormattedModalNotifications[key].map((item) =>
                                item.id === notification.id
                                  ? { ...item, isNotificationRead: true }
                                  : item,
                              );

                            setAllFormattedModalNotifications({
                              ...allFormattedModalNotifications,
                              [key]: updatedNotifications,
                            });
                          },
                        });
                      }}
                      intent={'tertiary'}
                    >
                      <div className='flex gap-2'>
                        <NotificationIcon
                          notificationType={
                            notification.type as unknown as NotificationType
                          }
                        />
                        <div>
                          <NotificationLabel notification={notification} />
                          <NotificationStatus
                            notificationTime={notification.timeAgo ?? ''}
                            notificationType={notification.type}
                          />
                          <NotificationButtons notification={notification} />
                        </div>
                      </div>
                    </Button>
                    {!notification.isNotificationRead && (
                      <div className='mt-2 size-2 rounded-full bg-blue-600' />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AllNotifications;
