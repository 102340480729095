import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { AllProductsApi } from '@/services/api/products';
import {
  AppKeyConstants,
  QUERY_CONSTANTS,
  TANSTACK_QUERY_KEY,
} from '@/utils/constants';
import { STOCK_DIVISONS } from '@/utils/constants/product-stock.constants';
import { getSearchParams } from '@/components/hooks/router/useGetSearchParams';
import { useURLSearchParams } from '@/components/hooks/router/useURLSearchParams';

export function useGetAllProducts() {
  const searchParams = new URLSearchParams();
  const { categoryId, promotionId } = useParams();

  const searchParamsRecord: Record<typeof QUERY_CONSTANTS.SEARCH, string[]> =
    getSearchParams([QUERY_CONSTANTS.SEARCH]);

  const searchParamsArray: string[] =
    searchParamsRecord[QUERY_CONSTANTS.SEARCH];

  const search: string = Array.isArray(searchParamsArray)
    ? searchParamsArray[0]
    : searchParamsArray;

  const { searchValue: subCategoryId } = useURLSearchParams(
    QUERY_CONSTANTS.SINGLE_PRODUCT_FILTERS.SUB_CATEGORY,
  );
  const { searchValue: page } = useURLSearchParams(QUERY_CONSTANTS.PAGE);
  const { searchValue: perPage } = useURLSearchParams(QUERY_CONSTANTS.LIMIT);
  const { searchValue: priceFrom } = useURLSearchParams(
    QUERY_CONSTANTS.PRICE_RANGE.FROM,
  );
  const { searchValue: priceTo } = useURLSearchParams(
    QUERY_CONSTANTS.PRICE_RANGE.TO,
  );
  const { searchValue: urlAvailableStockId } = useURLSearchParams(
    QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_AVAILABLE_STOCKS,
  );

  const {
    sizes: shoeSizes,
    shoe_color: colourHex,
    industries: industries,
    technologies: technologies,
  } = getSearchParams([
    QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_SIZE,
    QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_COLOR,
    QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_INDUSTRY_FOR,
    QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_TECHNOLOGY,
  ]);

  if (search) searchParams.append(QUERY_CONSTANTS.QUERY_Q, search);

  if (categoryId) searchParams.append(QUERY_CONSTANTS.CATEGORY_ID, categoryId);

  if (promotionId) searchParams.append(QUERY_CONSTANTS.PROMO_ID, promotionId);

  if (subCategoryId)
    searchParams.append(
      QUERY_CONSTANTS.SINGLE_PRODUCT_FILTERS.PRODUCT_TYPE_ID,
      String(subCategoryId),
    );

  if (page) searchParams.append(QUERY_CONSTANTS.PAGE, page);

  if (perPage) searchParams.append(AppKeyConstants.PAGE_SIZE, perPage);

  if (colourHex.length > 0) {
    colourHex.forEach((colour) => {
      searchParams.append('colours[]', colour);
    });
  }

  if (urlAvailableStockId) {
    const availableStockRange = STOCK_DIVISONS.find(
      (stockRange) =>
        Number(stockRange.threshold) === Number(urlAvailableStockId),
    )?.threshold;

    if (availableStockRange)
      if (availableStockRange === 100) {
        searchParams.append('stock[max]', availableStockRange.toString());
      } else {
        searchParams.append('stock[min]', availableStockRange.toString());
      }
  }

  if (shoeSizes.length > 0) {
    shoeSizes.forEach((size) => {
      searchParams.append(
        QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_SIZE + '[]',
        size,
      );
    });
  }

  if (industries.length > 0) {
    industries.forEach((industry) => {
      searchParams.append(
        QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_INDUSTRY_FOR + '[]',
        industry,
      );
    });
  }

  if (technologies.length > 0) {
    technologies.forEach((technology) => {
      searchParams.append(
        QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_TECHNOLOGY + '[]',
        technology,
      );
    });
  }

  if (priceFrom && priceTo) {
    searchParams.append('price[min]', priceFrom.split('.')[0]);
    searchParams.append('price[max]', priceTo.split('.')[0]);
  }

  const queryString = searchParams.toString();
  const url = queryString ? `?${queryString}` : '';

  return useQuery({
    queryKey: [
      TANSTACK_QUERY_KEY.PRODUCTS.ALL_PRODUCTS,
      search,
      subCategoryId,
      page,
      perPage,
      priceFrom,
      priceTo,
      colourHex,
      urlAvailableStockId,
      shoeSizes,
      industries,
      technologies,
    ],
    queryFn: () => AllProductsApi.getAllProducts(url)(),
  });
}
