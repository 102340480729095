import { z } from 'zod';

import { DiscountLabel, STATUS } from '@/utils/constants/promotion.constant';
import { PaginationResponseSchema } from '@/schemas/common.schema';

const MediaSchema = z.object({
  id: z.string().uuid(),
  type: z.string(),
  sort: z.number(),
  altText: z.string().nullable(),
  url: z.string().url(),
});

export const PromotionSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  description: z.string().nullable(),
  discountLabel: z.nativeEnum(DiscountLabel),
  discount: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  status: z.nativeEnum(STATUS),
  rules: z
    .object({
      excludes: z.array(z.string()),
      minPurchase: z.number().nonnegative(),
    })
    .nullable(),
  medias: z.array(MediaSchema.partial()),
});

export const GetPromotionListRequestSchema = z.void();
export const GetPromotionListResponseSchema = z.object({
  message: z.string(),
  timestamp: z.string(),
  data: z.array(PromotionSchema),
  meta: z.object({
    pagination: PaginationResponseSchema,
  }),
});

export const GetPromotionDetailRequestSchema = z.string().uuid();
export const GetPromotionDetailResponseSchema = z.object({
  message: z.string(),
  timestamp: z.string(),
  data: PromotionSchema,
});
