import { Link } from 'react-router-dom';

import { cn } from '@/utils/cn';
import { Avatar } from '@/components';
import { ROUTES } from '@/utils/constants';
import { TUserSchema } from '@/schemas/search/search.schema';
import { limitNameWithLength } from '@/utils/helper/limitNameWithLength';
import { highlightMatchingText } from '@/utils/helper/highlightText.helper';

const UserSearchList = ({
  searchValue,
  data,
}: {
  searchValue: string;
  data: TUserSchema;
}) => {
  const teams = data.teams ?? [];
  const maxTeamsToShow = teams.slice(0, 2);
  const remainingTeams = teams.slice(2);

  return (
    <div className='product-search-list group relative mb-4 flex w-full items-center gap-5 overflow-hidden rounded-md bg-white p-5 shadow-sm'>
      <div className='w-[56px]'>
        <Avatar
          avatar={[
            {
              imageSrc: data.avatar,
              name: data.firstName ?? 'n/a',
            },
          ]}
          size={56}
        />
      </div>
      <div className='flex w-[calc(100%_-_56px)] flex-wrap items-center gap-2 md:justify-between'>
        <div className='flex flex-col gap-1'>
          <div className='gap-1. flex items-center justify-start'>
            <Link
              to={`/${ROUTES.USER_AND_TEAMS.USER.LIST}/${data.id}`}
              className='inline-flex w-auto p-0 text-base font-medium text-gray-800 hover:bg-transparent hover:text-blue-600 hover:underline'
            >
              <span>
                {highlightMatchingText(
                  data.firstName! + ' ' + data.lastName!,
                  searchValue,
                )}
              </span>
            </Link>
          </div>
          <div className='flex w-full items-center justify-start gap-3'>
            <span className='text-sm font-medium text-gray-600'>
              {highlightMatchingText(data.email ?? '', searchValue)}
            </span>
          </div>
        </div>
        <div
          className={`${cn(
            'flex flex-wrap items-start gap-2 text-xs text-gray-600 md:flex-nowrap md:justify-end md:gap-7',
          )} relative`}
        >
          <div className='text-gray-600'>
            Team
            <span className='text-800 block font-bold'>
              {maxTeamsToShow.length > 0
                ? maxTeamsToShow
                    .map((team) => limitNameWithLength(team.name, 25))
                    .join(', ')
                : 'N/A'}{' '}
              {remainingTeams.length > 0 && ` + ${remainingTeams.length} more`}
            </span>
          </div>
          <div className='ml-0 text-gray-600'>
            Designation
            <span className='text-800 block font-bold'>
              {data.designation ?? '-'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSearchList;
