import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ROUTES } from '@/utils/constants';
import { useToggleState } from '@/components/hooks';
import { TProductSchema } from '@/schemas/search/search.schema';
import { formatCompanyPrice } from '@/utils/helper/country-locale';
import { Button, ImageComponent, ProductDetailDrawer } from '@/components';
import { highlightMatchingText } from '@/utils/helper/highlightText.helper';

const ProductSearchList = ({
  searchValue,
  data,
}: {
  searchValue: string;
  data: TProductSchema;
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const {
    state: showProductDetailDrawer,
    setState: setShowProductDetailDrawer,
    toggleState: viewRangeToggler,
  } = useToggleState();

  const navigate = useNavigate();

  return (
    <>
      <div
        className='product-search-list relative mb-4 flex w-full items-center gap-5 overflow-hidden rounded-md bg-white p-5 shadow-sm'
        onMouseEnter={() => {
          setIsHovered(true); // Set hover state to true
        }}
        onMouseLeave={() => {
          setIsHovered(false); // Set hover state to false
        }}
      >
        <div className='w-[56px]'>
          <ImageComponent
            src={
              data.media?.url ?? '../src/assets/images/placeholder-image.png'
            }
            alt={data.media?.altText ?? ''}
            width={56}
            height={56}
          />
        </div>
        <div className='flex w-[calc(100%_-_56px)] flex-wrap items-center justify-between gap-2'>
          <div className='flex w-full flex-col flex-wrap gap-1 lg:w-[calc(100%_-_175px)]'>
            <div>
              <Link
                to={`/${ROUTES.PRODUCT.ALL_PRODUCTS}/${data.categoryId}/details/${data.id}`}
                className='inline-flex w-auto p-0 text-base font-medium text-gray-800 hover:bg-transparent hover:text-blue-600 hover:underline'
              >
                <span>
                  {highlightMatchingText(data.name ?? '-', searchValue)}
                </span>
              </Link>
            </div>
            <div className='flex w-full flex-wrap items-center justify-start gap-3'>
              <span className='text-sm font-medium text-gray-600'>
                SKU {highlightMatchingText(data.skuCode ?? '-', searchValue)}
              </span>
              <span className='flex items-center gap-1.5'>
                <span
                  className='block h-3 w-3 rounded-full'
                  style={{ backgroundColor: `#${data.colourHex ?? '000'}` }}
                />
                {data.colourName ?? '-'}
              </span>
            </div>
          </div>

          <div className='relative'>
            {!isHovered && (
              <span className='inline-flex w-auto p-0 text-base font-medium text-gray-800'>
                {formatCompanyPrice(Number(data.price ?? 0))}
              </span>
            )}
            <div
              className={`absolute bottom-0 right-0 transition-all duration-100 ${
                isHovered
                  ? 'visible top-[-12px] translate-y-0 opacity-100'
                  : 'invisible translate-y-2 opacity-0'
              }`}
            >
              <Button
                intent='tertiary'
                size='md'
                variant='primary'
                className='p-0'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  viewRangeToggler();
                  navigate(`?q=${searchValue}&product-id=${data.id}`);
                }}
              >
                Quick Add
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ProductDetailDrawer
        showProductDetailDrawer={showProductDetailDrawer}
        setShowProductDetailDrawer={setShowProductDetailDrawer}
      />
    </>
  );
};

export default ProductSearchList;
