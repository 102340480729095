// roles.tsx
import { AbilityBuilder, createMongoAbility } from '@casl/ability';

export const PERMISSIONS = {
  MANAGE: 'manage',
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
};

// Entities to restrict access for "staff" role
export const RESTRICTED_SECTIONS = {
  CREDIT_INFORMATION: 'credit.view',
  COMPANY_SETTINGS: 'company.view',
  EDIT_COMPANY_SETTINGS: 'company.edit',
  SYSTEM_SETTINGS: 'system.view',
  CREATE_EDIT_USERS: 'user.create', // Changed to a specific user permission
  EDIT_USERS: 'user.edit', // New permission for editing users
  DELETE_USERS: 'user.delete', // New permission for deleting users
  CHANGE_STATUS: 'user.changeStatus', // New permission for changing user status
  SEND_2FA_PASSWORD_RESET_INSTRUCTIONS: 'user.send2FAInstructions', // New permission for sending 2FA instructions
};

/**
 * Defines the abilities for an admin user.
 *
 * @return {MongoAbility} The created MongoAbility instance.
 */
export function defineAbilitiesForAdmin() {
  // Create a new AbilityBuilder with MongoAbility.
  const { can, rules } = new AbilityBuilder(createMongoAbility);

  // Admin has full 'manage' permissions for all models.
  can(PERMISSIONS.MANAGE, 'all');

  // Create a MongoAbility instance with the defined rules.
  return createMongoAbility(rules);
}

/**
 * Defines the abilities for a staff user.
 *
 * @return {MongoAbility} The created MongoAbility instance.
 */
export function defineAbilitiesForStaff() {
  const { can, cannot, rules } = new AbilityBuilder(createMongoAbility);

  // Grant staff some permissions (e.g., view certain pages or data)
  can(PERMISSIONS.READ, 'all'); // Staff can read/view most things

  // Staff restrictions
  cannot(PERMISSIONS.MANAGE, RESTRICTED_SECTIONS.CREDIT_INFORMATION);
  cannot(PERMISSIONS.MANAGE, RESTRICTED_SECTIONS.COMPANY_SETTINGS);
  cannot(PERMISSIONS.MANAGE, RESTRICTED_SECTIONS.SYSTEM_SETTINGS);
  cannot(
    [PERMISSIONS.CREATE, PERMISSIONS.UPDATE],
    RESTRICTED_SECTIONS.CREATE_EDIT_USERS,
  );

  return createMongoAbility(rules);
}

/**
 * Defines the abilities for a user based on the given permissions.
 *
 * @param {Array} permissions - An array of permissions.
 * @return {MongoAbility} The created MongoAbility instance.
 */
export function defineAbilitiesForUser(permissions: string[]) {
  // Create a new AbilityBuilder with MongoAbility.
  const { can, rules } = new AbilityBuilder(createMongoAbility);

  // Dynamically set 'view' permissions based on the given permissions.
  // For each permission in the permissions array, grant 'view' permission.
  permissions.forEach((permission) => {
    can('view', permission);
  });

  // Create a MongoAbility instance with the defined rules.
  return createMongoAbility(rules);
}

export const getUserRole = (role: string) => {
  switch (role) {
    case 'SUPER_ADMIN':
      return 'Super Admin';
    case 'ADMIN':
      return 'Admin';
    case 'STAFF':
      return 'Staff';
    default:
      return 'N/A';
  }
};
