import { z } from 'zod';

import { CarriageType } from '@/utils/constants/general.constant';

export const SingleCarriageDetailsSchema = z.object({
  cost: z.string(),
  id: z.string().uuid(),
  type: z.nativeEnum(CarriageType),
});

export type TSingleCarriageDetailsSchema = z.infer<
  typeof SingleCarriageDetailsSchema
>;
