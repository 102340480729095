import { z } from 'zod';

import {
  NotificationRequestSchema,
  NotificationResponseSchema,
  ClearAllNotificationsResponseSchema,
  NotificationMarkAsReadResponseSchema,
} from '@/schemas/api/notifications/notifications';
import { api } from '@/utils/helper';
import { API_ENDPOINT } from '@/utils/constants';

const getNotifications = (path: string) =>
  api<
    z.infer<typeof NotificationRequestSchema>,
    z.infer<typeof NotificationResponseSchema>
  >({
    method: 'GET',
    path,
    requestSchema: NotificationRequestSchema,
    responseSchema: NotificationResponseSchema,
  });

const markNotificationAsRead = (notificationId: string) =>
  api<
    z.infer<typeof NotificationRequestSchema>,
    z.infer<typeof NotificationMarkAsReadResponseSchema>
  >({
    method: 'GET',
    path: `${API_ENDPOINT.NOTIFICATIONS.BASE}/${notificationId}/markasread`,
    requestSchema: NotificationRequestSchema,
    responseSchema: NotificationMarkAsReadResponseSchema,
  });

const clearAllNotifications = api<
  z.infer<typeof NotificationRequestSchema>,
  z.infer<typeof ClearAllNotificationsResponseSchema>
>({
  method: 'GET',
  path: API_ENDPOINT.NOTIFICATIONS.CLEAR_ALL,
  requestSchema: NotificationRequestSchema,
  responseSchema: ClearAllNotificationsResponseSchema,
});

export const NotificationAPI = {
  getNotifications,
  markNotificationAsRead,
  clearAllNotifications,
};
