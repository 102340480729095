import { getUserFullName } from '@/utils/helper';
import { getNotificationKey } from '@/utils/helper/notifications.helper';

const NotificationStatus = ({
  notificationTime,
  notificationType,
}: {
  notificationTime: string;
  notificationType: string;
}) => {
  return (
    <div className='flex items-center gap-2 text-sm font-normal text-gray-500'>
      <p>{notificationTime}</p>
      <div className='size-2 rounded-full bg-gray-500' />
      <span>{getNotificationKey(getUserFullName(notificationType))}</span>
    </div>
  );
};

export default NotificationStatus;
