import React from 'react';
import ReactDOM from 'react-dom/client';

import '@/index.css';
import 'react-loading-skeleton/dist/skeleton.css';

import App from './App';
import { ReactQueryWrapper } from '@/components/lib';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://c95f0bff818d89140f5ed8d3b479cad4@o4509055717146624.ingest.de.sentry.io/4509087332892752',
});

const rootElement = document.getElementById('root');

if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <ReactQueryWrapper>
        <App />
      </ReactQueryWrapper>
    </React.StrictMode>,
  );
}
