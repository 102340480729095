import { Link } from '../link';
import { ROUTES } from '@/utils/constants';
import useNotificationStore from '@/store/notification.store';
import { NotificationType } from '@/utils/constants/notification.constants';
import { TSingleNotificationResponseSchema } from '@/schemas/api/notifications/notifications';

const NotificationButtonLink = ({
  path,
  label,
}: {
  path: string;
  label: string;
}) => {
  const { showNotificationModal, setShowNotificationModal } =
    useNotificationStore();

  return (
    <Link
      size={'xs'}
      to={path}
      onClick={() => {
        if (!showNotificationModal) return;
        setShowNotificationModal(false);
      }}
    >
      {label}
    </Link>
  );
};

const NotificationButtons = ({
  notification,
}: {
  notification: TSingleNotificationResponseSchema;
}) => {
  const { type, data } = notification;
  const { orderId } = data;

  const getNotificationButton = () => {
    switch (type) {
      case NotificationType.ORDERS:
        return (
          <div className='flex py-3'>
            <NotificationButtonLink
              path={`/${ROUTES.ORDERS.MY_ORDERS}/${orderId}`}
              label='View Order'
            />
          </div>
        );

      default:
        return null;
    }
  };

  return <div>{getNotificationButton()}</div>;
};

export default NotificationButtons;
