import { cn } from '@/utils/cn';
import { useCustomMediaQuery } from '@/components/hooks';
import ClearAllButton from '@/pages/notifications/components/clearAllButton';

const NotificationModalHeader = ({
  isNotificationEmpty,
}: {
  isNotificationEmpty: boolean;
}) => {
  const isHeightSmallerThan550px: boolean = useCustomMediaQuery(550);

  return (
    <div
      className={cn(
        'flex items-center justify-between pt-4',
        isHeightSmallerThan550px && 'pt-2',
      )}
    >
      <h6>Notification</h6>
      <ClearAllButton isNotificationEmpty={isNotificationEmpty} />
    </div>
  );
};

export default NotificationModalHeader;
