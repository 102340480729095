import { useContext } from 'react';

import { cn } from '@/utils/cn';
import { SimpleTable } from '@/components/ui/table/NormalTable';
import { useCustomMediaQuery, useTable } from '@/components/hooks';
import { CreateUserTableContext } from '@/pages/users-and-teams/users/modals';
import { useCreateUserListColumn } from '@/pages/users-and-teams/users/create-user/CreateUserColumn';

/**
 * @description CreateUserTable component
 */
export function CreateUserTable() {
  const isHeightSmallerThan400px: boolean = useCustomMediaQuery(400);
  const isHeightSmallerThan550px: boolean = useCustomMediaQuery(550);
  const isHeightSmallerThan650px: boolean = useCustomMediaQuery(650);

  const { createUserList } = useContext(CreateUserTableContext);

  const { columns } = useCreateUserListColumn();

  const { table } = useTable(columns, createUserList);

  const rowCount = table.getRowCount();

  if (rowCount <= 0)
    return (
      <div
        className={cn(
          'flex items-center justify-center py-36',
          isHeightSmallerThan400px
            ? 'py-2'
            : isHeightSmallerThan550px
              ? 'py-4'
              : isHeightSmallerThan650px && 'py-6',
        )}
      >
        <p className='text-gray-400'>
          Once you&apos;ve added a user, they&apos;ll appear in this space.
        </p>
      </div>
    );

  return (
    <>
      <p className='text-lg font-semibold text-gray-800'>Users ({rowCount})</p>
      <SimpleTable
        table={table}
        columns={columns}
      />
    </>
  );
}
