import { useMemo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { isEmpty } from '@/utils/helper';
import useNotificationStore from '@/store/notification.store';
import { API_ENDPOINT } from '@/utils/constants/api-endpoint.constant';
import { TANSTACK_QUERY_KEY } from '@/utils/constants/query-key.constant';
import { NotificationAPI } from '@/services/api/notifications/notifications';
import { TNotificationResponseSchema } from '@/schemas/api/notifications/notifications';

const useGetNotifications = ({
  page = 1,
  pageSize = 10,
  unread,
}: {
  page?: number;
  pageSize?: number;
  unread?: boolean;
}) => {
  const { setIsNotificationEmpty, setIsNotificationAPILoading } =
    useNotificationStore();

  const path = useMemo(() => {
    const searchParams = new URLSearchParams();
    if (page) searchParams.append('page', page.toString());
    if (pageSize) searchParams.append('pageSize', pageSize.toString());
    if (unread) searchParams.append('status', 'unread');
    return `${API_ENDPOINT.NOTIFICATIONS.ALL}?${searchParams.toString()}`;
  }, [page, pageSize, unread]);

  const handleSetIsNotificationEmpty = (data: TNotificationResponseSchema) => {
    if (isEmpty(data.data)) {
      setIsNotificationEmpty(unread ? 'unread' : 'all', true);
    }
  };

  const query = useQuery({
    queryKey: [TANSTACK_QUERY_KEY.NOTIFICATION.ALL, page, pageSize, unread],
    queryFn: async () => {
      const data = await NotificationAPI.getNotifications(path)();
      handleSetIsNotificationEmpty(data);
      return data;
    },
    gcTime: 0,
    staleTime: 0,
  });

  useEffect(() => {
    setIsNotificationAPILoading(query.isLoading);
  }, [query.isLoading, setIsNotificationAPILoading]);

  return query;
};

export default useGetNotifications;
