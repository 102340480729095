import { useMemo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';

import {
  Link,
  Badge,
  Dropdown,
  ColorBadge,
  IconsComponent,
  ImageComponent,
  Tooltip,
  Button,
  ProductDetailDrawer,
} from '@/components';
import { QUERY_CONSTANTS, ROUTES } from '@/utils/constants';
import { PRICETYPE } from '@/utils/constants/product-stock.constants';
import { useToggleState } from '@/components/hooks';
import { useNavigate } from 'react-router-dom';
import { formatCompanyPrice } from '@/utils/helper/country-locale';

export interface RealTimeStockProductList {
  id: string | null | undefined;
  categoryId: string | null | undefined;
  sku: string | null | undefined;
  name: string | null | undefined;
  color: {
    name: string | null | undefined;
    hex: string | null | undefined;
  };
  image: {
    src: string;
    alt: string;
  };
  size: string[];
  category: string;
  stock: number | null | undefined;
  buyPrice: number;
  rrp: number;
  isDynamicPricing: boolean;
  priceType: PRICETYPE;
  updatedAt?: string;
  stockUpdatedAt?: string | null;
}

/**
 * @description SizeColumn component to display product sizes
 */
function SizeColumn(info: CellContext<RealTimeStockProductList, unknown>) {
  const sizes = info.row.original.size;
  const maxSizesToShow = sizes.slice(0, 4);
  const remainingSizes = sizes.slice(4);

  return (
    <>
      {maxSizesToShow.length > 0
        ? maxSizesToShow.map((size) => size).join(', ')
        : 'N/A'}
      {remainingSizes.length > 0 && (
        <Tooltip className='action'>
          <Tooltip.Trigger>
            <Button
              intent='tertiary'
              variant='gray'
              className='p-0 font-normal text-gray-500'
              size='xs'
            >
              {' '}
              + {remainingSizes.length} sizes
            </Button>
          </Tooltip.Trigger>
          <Tooltip.Content>
            <ul className='h-full max-h-24 overflow-y-auto'>
              {remainingSizes.map((size, index) => (
                <li key={index}>{size}</li>
              ))}
            </ul>
          </Tooltip.Content>
        </Tooltip>
      )}
    </>
  );
}

/**
 * @description ProductColumn component to display product image and name
 */
function ProductColumn(info: CellContext<RealTimeStockProductList, unknown>) {
  const { image, name } = info.row.original;
  return (
    <figure className='flex items-center space-x-2'>
      <div className='size-9 rounded border border-gray-200 object-contain'>
        <ImageComponent
          alt={image.alt}
          src={image.src}
          classes='object-cover object-center w-full h-full'
        />
      </div>
      <figcaption>
        <p className='whitespace-nowrap text-gray-800'>{name}</p>
      </figcaption>
    </figure>
  );
}

/**
 * @description ActionColumn component to display action buttons for user
 */
function ActionColumn(info: CellContext<RealTimeStockProductList, unknown>) {
  const { id, categoryId } = info.row.original;

  const viewDetailsLink = `/${ROUTES.PRODUCT.ALL_PRODUCTS}/${categoryId}/details/${id}`;

  const navigate = useNavigate();

  const {
    state: showProductDetailDrawer,
    setState: setShowProductDetailDrawer,
    toggleState: toggleShowProductDetailDrawer,
  } = useToggleState();

  return (
    <>
      <Dropdown className='action'>
        <Dropdown.Toggle
          size='icon'
          intent='tertiary'
        >
          <IconsComponent
            icon='reg-dots-three'
            size='sm'
          />
        </Dropdown.Toggle>
        <Dropdown.Content position='right'>
          <Link
            size='xs'
            variant='gray'
            intent='tertiary'
            className='w-full justify-start px-3 py-2 font-normal text-gray-800 hover:bg-gray-100'
            to={viewDetailsLink}
          >
            View Details
          </Link>
          <Button
            size='xs'
            variant='gray'
            intent='tertiary'
            className='w-full justify-start px-3 py-2 font-normal text-gray-800 hover:bg-gray-100'
            onClick={() => {
              navigate(
                `${location.pathname}?${QUERY_CONSTANTS.SINGLE_PRODUCT_FILTERS.PRODUCT_ID}=${id}`,
              );
              toggleShowProductDetailDrawer();
            }}
          >
            Quick Add
          </Button>
        </Dropdown.Content>
      </Dropdown>
      <ProductDetailDrawer
        showProductDetailDrawer={showProductDetailDrawer}
        setShowProductDetailDrawer={setShowProductDetailDrawer}
      />
    </>
  );
}

export function useRealTimeStockColumn() {
  const columns = useMemo<ColumnDef<RealTimeStockProductList>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Product',
        enableSorting: false,
        cell: (info) => <ProductColumn {...info} />,
      },
      {
        accessorKey: 'sku',
        header: 'SKU',
        enableSorting: false,
        cell: (info) => info.getValue() ?? 'N/A',
      },
      {
        accessorKey: 'color',
        header: 'Color',
        enableSorting: false,
        cell: (info) => {
          const color = info.row.original.color;
          return (
            <ColorBadge shoeProductColor={color.hex ?? ''}>
              {color.name}
            </ColorBadge>
          );
        },
      },
      {
        accessorKey: 'size',
        header: 'Size',
        enableSorting: false,
        cell: (info) => <SizeColumn {...info} />,
      },
      {
        accessorKey: 'price',
        header: 'Price',
        cell: (info) => {
          const price = info.row.original.rrp;
          return formatCompanyPrice(price);
        },
      },
      {
        accessorKey: 'stock',
        header: 'Stock',
        cell: (info) => {
          const stock = Number(info.row.original.stock);

          return !Number.isNaN(stock) && stock > 0 ? (
            stock
          ) : (
            <Badge variant='primary'>Out of Stock</Badge>
          );
        },
      },
      {
        accessorKey: 'category',
        header: 'Category',
        cell: (info) => info.getValue() ?? 'N/A',
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        enableSorting: false,
        cell: (info) => <ActionColumn {...info} />,
      },
    ],
    [],
  );
  return { columns };
}
