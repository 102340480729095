import {
  CategorizedNotificationsMap,
  NotificationCategory,
} from '../constants/notification.constants';
import { formatCompanyPrice } from './country-locale';
import { DiscountLabel } from '../constants/promotion.constant';
import { formatDateNotification, getTimeDifference } from './datetime.helper';
import { TSingleNotificationResponseSchema } from '@/schemas/api/notifications/notifications';

export const transformNotification = (
  data: TSingleNotificationResponseSchema[],
) => {
  const CategorizedNotifications: CategorizedNotificationsMap<NotificationCategory> =
    {
      Today: [],
      Yesterday: [],
      This_Month: [],
      Older: [],
    };

  const sortedNotification = data
    .slice()
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const formatDate = (date: Date) =>
    date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });

  const todayKey = formatDate(today);
  const yesterdayKey = formatDate(yesterday);

  sortedNotification.forEach((item) => {
    const itemDate = new Date(item.createdAt);
    const itemKey = formatDate(itemDate);

    const getCategory = () => {
      if (itemKey === todayKey) return 'Today';
      if (itemKey === yesterdayKey) return 'Yesterday';
      if (
        itemDate.getFullYear() === today.getFullYear() &&
        itemDate.getMonth() === today.getMonth()
      ) {
        return 'This_Month';
      }
      return 'Older';
    };

    const category = getCategory();
    const timeAgo =
      category === 'Today'
        ? getTimeDifference(item.createdAt)
        : formatDateNotification(item.createdAt);

    const isNotificationRead = item.users[0].readAt !== null;

    CategorizedNotifications[category].push({
      ...item,
      timeAgo,
      isNotificationRead,
    });
  });

  const notificationKeys: (keyof typeof CategorizedNotifications)[] = [
    'Today',
    'Yesterday',
    'This_Month',
    'Older',
  ];

  const filteredNotifications = notificationKeys.reduce<
    Record<string, TSingleNotificationResponseSchema[]>
  >((acc, key) => {
    if (CategorizedNotifications[key].length > 0) {
      acc[key] = CategorizedNotifications[key];
    }
    return acc;
  }, {});

  return filteredNotifications;
};

export function extractSSEData<T extends Record<string, unknown>>(
  response: string[],
): T | null {
  if (!response.length) return null;

  const match = response[0].match(/data: (.+)/);
  if (match?.[1]) {
    try {
      return JSON.parse(match[1]) as T;
    } catch (e) {
      console.error('Failed to parse JSON:', e);
      return null;
    }
  }

  return null;
}

export const getNotificationKey = (key: string) => {
  if (key.includes('_')) {
    const [first, second] = key.split('_');

    return `${first} ${second}`;
  } else {
    return key;
  }
};

export const getDiscountLabel = (
  value: number,
  discountType: DiscountLabel,
) => {
  if (discountType === DiscountLabel.PERCENTAGE) {
    return `${value}%`;
  } else {
    return formatCompanyPrice(value);
  }
};

export interface EventDataInteface {
  cartItemCount: number;
  notificationCount: number;
  currency: string;
  dateFormat: string;
  timeFormat: string;
}
