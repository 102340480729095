import { useRef } from 'react';

import {
  useCustomMediaQuery,
  useIsDeviceTabletOrLarger,
  useOutsideClick,
} from '@/components/hooks';
import { cn } from '@/utils/cn';
import { Button } from '../../button';
import ViewAll from './components/viewAll';
import { ROUTES } from '@/utils/constants';
import IconsComponent from '../../icons/IconsComponent';
import AllNotifications from './components/allNotifications';
import NotificationTabs from './components/notificationTabs';
import useNotificationStore from '@/store/notification.store';
import UnreadNotifications from './components/unreadNotifications';
import NotificationModalHeader from './components/notificationModalHeader';
import EmptyNotificationsMessage from './components/emptyNotificationsMessage';

const HeaderNotification = () => {
  const notificationRef = useRef<HTMLDivElement>(null);
  const isTabletOrLarger: boolean = useIsDeviceTabletOrLarger();
  const isHeightSmallerThan400px: boolean = useCustomMediaQuery(400);
  const isHeightSmallerThan550px: boolean = useCustomMediaQuery(550);
  const isHeightSmallerThan650px: boolean = useCustomMediaQuery(650);

  const {
    showNotificationModal,
    setShowNotificationModal,
    activeNotificationTab,
    resetNotificationStore,
    clearNotificationDialogOpen,
    isAllNotificationEmpty,
    v12SSEEventCount,
    isNotificationAPILoading,
    setActiveNotificationTab,
  } = useNotificationStore();

  const isCurrentPathNotification = window.location.pathname.includes(
    ROUTES.NOTIFICATIONS,
  );

  const toggleModal = () => {
    setShowNotificationModal(!showNotificationModal);
    if (activeNotificationTab !== 'all') {
      setActiveNotificationTab('tab', 'all');
    }
    resetNotificationStore(isCurrentPathNotification);
  };

  useOutsideClick(notificationRef, () => {
    if (clearNotificationDialogOpen) return;
    setShowNotificationModal(false);
  });

  const heightClass = isHeightSmallerThan400px
    ? 'h-[330px]'
    : isHeightSmallerThan550px
      ? 'h-[400px]'
      : isHeightSmallerThan650px
        ? 'h-[480px]'
        : '';

  const renderModal = () => (
    <div
      className={cn(
        'absolute h-[600px] rounded-lg border border-gray-50 bg-white shadow-lg',
        !isTabletOrLarger ? 'right-[-80px] w-screen' : 'right-2 size-[600px]',
        heightClass,
      )}
      ref={notificationRef}
    >
      <div className='grid h-full grid-rows-12'>
        <div
          className={cn(
            'row-span-2 grid px-6',
            isHeightSmallerThan400px ? '' : 'border-b border-gray-200',
          )}
        >
          <NotificationModalHeader
            isNotificationEmpty={isAllNotificationEmpty}
          />
          <NotificationTabs isNotificationEmpty={isAllNotificationEmpty} />
        </div>
        <div
          className={cn(
            'row-span-8 overflow-y-auto',
            isHeightSmallerThan400px ? 'py-8' : 'py-4',
          )}
        >
          {isAllNotificationEmpty ? (
            <EmptyNotificationsMessage />
          ) : activeNotificationTab === 'all' ? (
            <AllNotifications />
          ) : (
            <UnreadNotifications />
          )}
        </div>
        {!isAllNotificationEmpty && !isNotificationAPILoading && <ViewAll />}
      </div>
    </div>
  );

  return (
    <div className='relative ml-3'>
      <Button
        variant='blue'
        intent='tertiary'
        className='relative p-0'
        onClick={toggleModal}
      >
        <IconsComponent icon='fill-notifications' />
        {v12SSEEventCount.notificationCount > 0 && (
          <div className='absolute bottom-3 left-4 z-10 grid aspect-square min-h-5 min-w-5 items-center justify-center rounded-full bg-yellow-400'>
            <p className='p-[2px] text-xs font-normal text-gray-800'>
              {v12SSEEventCount.notificationCount > 99
                ? '99+'
                : v12SSEEventCount.notificationCount}
            </p>
          </div>
        )}
      </Button>

      {showNotificationModal && renderModal()}
    </div>
  );
};

export default HeaderNotification;
