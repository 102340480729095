import { z } from 'zod';

export const CheckoutSchema = z.object({
  shippingAddress: z.union([z.number(), z.string()]),
  billingAddress: z.union([z.number(), z.string()]),
  cartId: z.string().uuid(),
  orderTotal: z.number().nullable(),
  paymentType: z.enum(['DIRECT', 'CREDIT']),
  purchaseOrderNumber: z
    .string()
    .max(50, 'Purchase Order Number is too long')
    .nullable(),
  expectedDeliveryDate: z.string().nullable(),
});

export type TCheckout = z.infer<typeof CheckoutSchema>;
