import { V12_CURRENCY_TYPES } from './currency-helper';

export const pageSize = 5;

export enum CarriageType {
  LOCAL = 'LOCAL',
  INTERNATIONAL = 'INTERNATIONAL',
  FREE = 'FREE',
}

export const LOCAL_COUNTRIES = ['GB'];

export const SYSTEM_TIME_FORMATS = ['HOUR_12', 'HOUR_24'] as const;
export type TSystemTimeFormats = (typeof SYSTEM_TIME_FORMATS)[number];

export const SYSTEM_DATE_FORMATS = [
  'MM_DD_YYYY',
  'DD_MM_YYYY',
  'YYYY_MM_DD',
  'DD_MMMM_YYYY',
] as const;
export type TSystemDateFormats = (typeof SYSTEM_DATE_FORMATS)[number];

export const SYSTEM_CURRENCY_VALUES = V12_CURRENCY_TYPES.map(
  ({ value }) => value,
);
export type TSystemCurrencyFormats = (typeof SYSTEM_DATE_FORMATS)[number];
