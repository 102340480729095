import { Link, Modal } from '@/components';
import { ROUTES } from '@/utils/constants';
import { Banner } from '@/pages/marketing-resources/flash-promotions/quick-view/Banner';

export function QuickViewModal({
  promotionId,
  showQuickView,
  isPromotionActive,
  setShowQuickView,
}: {
  promotionId: string;
  showQuickView: boolean;
  isPromotionActive: boolean;
  setShowQuickView: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Modal
      className='w-full'
      showModal={showQuickView}
      setShowModal={setShowQuickView}
    >
      <Modal.Content className='lg:max-h-auto bottom-10 mx-auto max-h-[80vh] w-[95vw] bg-white lg:w-[860px]'>
        <Modal.Body className='border-0 p-0'>
          <Banner
            promotionId={promotionId}
            isPromotionActive={isPromotionActive}
          />
        </Modal.Body>
        <Modal.Footer className='bg-white'>
          <Modal.CloseButton>Cancel</Modal.CloseButton>
          {isPromotionActive && (
            <Link
              to={`/${ROUTES.MARKETING_RESOURCES.FLASH_PROMOTIONS}/${promotionId}`}
            >
              Shop Products
            </Link>
          )}
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
