import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  ComponentProps,
} from 'react';

import { cn } from '@/utils/cn';
import { inputVariants, IconsComponent } from '@/components';

interface RangeProps {
  min: number;
  max: number;
  step?: number;
  range: {
    inputFrom: number;
    inputTo: number;
  };
  setRange: React.Dispatch<
    React.SetStateAction<{
      inputFrom: number;
      inputTo: number;
    }>
  >;
  children?: React.ReactNode;
}

const RangeContext = createContext<RangeProps | undefined>(undefined);

export const useStockRange = () => {
  const context = useContext(RangeContext);

  if (!context)
    throw new Error('useStockRange must be used within a StockRangeProvider');

  return context;
};

function Range({ max, min, range, setRange, step = 1, children }: RangeProps) {
  return (
    <RangeContext.Provider value={{ range, setRange, max, min, step }}>
      <div
        className='flex w-fill-available flex-col gap-6'
        data-cy='Range-component'
      >
        {children}
      </div>
    </RangeContext.Provider>
  );
}

function RangeSlider({
  className = 'bg-blue-500',
  prefixSymbol,
  ...props
}: ComponentProps<'span'> & { prefixSymbol?: string }) {
  const {
    range: { inputFrom, inputTo },
    max,
    min,
    step,
    setRange,
  } = useStockRange();

  const sliderRef = useRef<HTMLSpanElement>(null);

  const calculatePercentages = useCallback(() => {
    const fromPercent = ((inputFrom - min) / (max - min)) * 100;
    const toPercent = ((inputTo - min) / (max - min)) * 100;

    return { fromPercent, toPercent };
  }, [inputFrom, inputTo, min, max]);

  useEffect(() => {
    if (!sliderRef.current) return;

    const { fromPercent, toPercent } = calculatePercentages();
    sliderRef.current.style.left = `${fromPercent}%`;
    sliderRef.current.style.right = `${100 - toPercent}%`;
  }, [calculatePercentages]);

  const handleInputFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    if (newValue >= min && newValue < inputTo) {
      setRange({ inputFrom: newValue, inputTo });
    }
  };

  const handleInputTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    if (newValue > inputFrom && newValue <= max) {
      setRange({ inputFrom, inputTo: newValue });
    }
  };

  const handleTrackClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!sliderRef.current) return;

    const track = sliderRef.current.parentElement;
    if (!track) return;

    const trackRect = track.getBoundingClientRect();
    const clickPosition = e.clientX - trackRect.left;
    const trackWidth = trackRect.width;

    const clickPercentage = (clickPosition / trackWidth) * 100;
    const newValue = Math.round((clickPercentage / 100) * (max - min) + min);

    const distanceFromInputFrom = Math.abs(newValue - inputFrom);
    const distanceFromInputTo = Math.abs(newValue - inputTo);

    if (distanceFromInputFrom < distanceFromInputTo) {
      if (newValue >= min && newValue < inputTo) {
        setRange({ inputFrom: newValue, inputTo });
      }
    } else {
      if (newValue > inputFrom && newValue <= max) {
        setRange({ inputFrom, inputTo: newValue });
      }
    }
  };

  return (
    <>
      <div className='w-full bg-blue-50'>
        <div
          className='bg-slate-200 relative h-[5px] cursor-pointer rounded-sm'
          role='button'
          tabIndex={0}
          onClick={handleTrackClick}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleTrackClick(
                e as unknown as React.MouseEvent<HTMLDivElement>,
              );
            }
          }}
        >
          <span
            ref={sliderRef}
            className={cn(
              'absolute left-[30%] right-[30%] h-full rounded-sm transition-transform duration-200',
              className,
            )}
            data-cy='active range'
            {...props}
          />
        </div>
        <div className='range-input relative'>
          <input
            type='range'
            min={min}
            max={max}
            step={step}
            value={inputFrom}
            onChange={handleInputFrom}
            data-cy={`from-range: ${inputFrom}`}
          />
          <input
            type='range'
            min={min}
            max={max}
            step={step}
            value={inputTo}
            onChange={handleInputTo}
            data-cy={`to-range: ${inputTo}`}
          />
        </div>
      </div>
      <div className='mt-4 flex items-center justify-between'>
        <p
          className='text-base text-gray-800'
          data-cy={`from-range: ${inputFrom}`}
        >
          {prefixSymbol}
          {inputFrom}
        </p>
        <p
          className='text-base text-gray-800'
          data-cy={`from-range: ${inputTo}`}
        >
          {prefixSymbol}
          {inputTo}
        </p>
      </div>
    </>
  );
}

function RangeInput() {
  const {
    range: { inputFrom, inputTo },
    setRange,
    max,
    min,
  } = useStockRange();

  const [error, setError] = useState({ from: false, to: false });
  const [input, setInput] = useState<{
    from: number | undefined;
    to: number | undefined;
  }>({ from: inputFrom, to: inputTo });

  const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const parsedValue = parseInt(value, 10);

    const isValid = value !== '' && parsedValue >= min && parsedValue < inputTo;

    setError((previous) => ({ ...previous, from: !isValid }));

    setInput((prevInput) => ({
      ...prevInput,
      from: value === '' ? undefined : parsedValue,
    }));
  };

  const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const parsedValue = parseInt(value, 10);

    const isValid =
      value !== '' && parsedValue <= max && parsedValue > inputFrom;

    setError((previous) => ({ ...previous, to: !isValid }));

    setInput((previous) => ({
      ...previous,
      to: value === '' ? undefined : parsedValue,
    }));
  };

  const handleFromBlur = () => {
    const newValue = input.from ?? min;
    if (newValue >= min && newValue < inputTo) {
      setRange({ inputFrom: newValue, inputTo });
    } else {
      setInput({ ...input, from: inputFrom });
    }
    setError({ ...error, from: false });
  };

  const handleToBlur = () => {
    const newValue = input.to ?? max;
    if (newValue <= max && newValue > inputFrom) {
      setRange({ inputFrom, inputTo: newValue });
    } else {
      setInput({ ...input, to: inputTo });
    }
    setError({ ...error, to: false });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleFromBlur();
      handleToBlur();
    }
  };

  useEffect(() => {
    setInput({ from: inputFrom, to: inputTo });
  }, [inputFrom, inputTo]);

  return (
    <div className='flex items-center space-x-2'>
      <input
        type='number'
        min={min}
        max={max}
        placeholder='Minimum'
        value={input.from ?? ''}
        onChange={handleFromChange}
        onBlur={handleFromBlur}
        onKeyDown={handleKeyDown}
        className={cn(
          inputVariants({}),
          error.from && 'bg-red-50 focus:border-red-600',
        )}
      />
      <div className=''>
        <IconsComponent
          icon='reg-minus'
          fill='#D1D5DB'
        />
      </div>
      <input
        type='number'
        value={input.to ?? ''}
        placeholder='Maximum'
        min={min}
        max={max}
        onKeyDown={handleKeyDown}
        onChange={handleToChange}
        onBlur={handleToBlur}
        className={cn(
          inputVariants({}),
          error.to && 'bg-red-50 focus:border-red-600',
        )}
      />
    </div>
  );
}

Range.Input = RangeInput;
Range.Slider = RangeSlider;

export { Range };
