export const ROUTES = Object.freeze({
  LOGIN: 'login',
  TWOFA_RESET_VERIFY: 'users/:userId/2fa-reset',
  REGISTER: {
    REGISTER: 'register',
    COMPLETE_REGISTRATION: 'companies/:companyId/registration/:token',
    REGISTER_COMPLETE: 'register/complete',
  },
  CREATE_ACCOUNT: {
    NEW_PASSWORD: 'users/:userId/invited/:token',
    COMPLETE_INFORMATION: 'create-account/complete-information',
  },
  FORGOT_PASSWORD: {
    FORGOT_PASSWORD: 'forgot-password',
    CREATE_NEW: 'users/:userId/reset-password/:token',
  },
  OVERVIEW: 'overview',
  PRODUCT: {
    ALL_PRODUCTS: 'products/all-products',
    FILTERS: 'products/all-products/:categoryId',
    DETAILS: 'products/all-products/:categoryId/details/:id',
    BUNDLES: 'products/bundles',
    COMPARISON: 'products/comparison',
  },
  REAL_TIME_STOCK: 'real-time-stock',
  ORDERS: {
    MY_ORDERS: 'orders/my-orders',
    MY_RETURNS: 'orders/my-returns',
    MY_DETAILS: 'orders/my-orders/:id',
  },
  INVOICES: 'invoices',
  USER_AND_TEAMS: {
    USER: {
      LIST: 'management/users',
      DETAILS: 'management/users/:id',
    },
    TEAM: {
      LIST: 'management/teams',
      DETAILS: 'management/teams/id/:id',
      CREATE: 'management/teams/create',
    },
  },
  MARKETING_RESOURCES: {
    FLASH_PROMOTIONS: 'marketing/flash-promotions',
    FLASH_PROMOTIONS_DETAILS: 'marketing/flash-promotions/:promotionId',
    MARKETING_ASSETS: 'marketing/assets',
    POSTER_TEMPLATE: 'marketing/poster-templates',
    PROPOSALS: 'marketing/proposals',
    FLASH_PROMOTIONS_PRODUCT_DETAILS:
      'marketing/flash-promotions/:promotionId/detail/:id',
  },
  SETTINGS: {
    GENERAL_SETTINGS: 'settings/general',
    PERSONAL_SETTINGS: 'settings/personal',
    CREDIT_INFORMATION_SETTINGS: 'settings/credit-information',
    REBATE_AGGREMENT_SETTINGS: 'settings/reabte-aggrement',
  },
  SUPPORT: {
    SALES_REPORT: 'support/sales-report',
    USER_REPORT: 'support/user-report',
  },
  NOT_FOUND: 'not-found',
  CART: 'cart',
  CHECKOUT: 'cart/checkout',
  PAYMENT: {
    STRIPE_CALLBACK: 'callback',
    CREDIT_CALLBACK: 'credit-callback',
  },
  NOTIFICATIONS: 'notifications',
  SEARCH: 'search',
});
