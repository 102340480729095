export const AUTHENTICATION = {
  LOGIN: 'auth/login',
  LOGOUT: 'auth/logout',
  GENERATE_2FA: 'auth/2fa/generate',
  VERIFY_OTP: 'auth/2fa/verify',
  VERIfY_TOKEN: 'registration/verify-token',
  REGISTER: {
    REGISTER: 'companies/registration',
    COMPANY: 'registration',
  },
  FORGOT_PASSWORD: {
    RESET: 'auth/forget-password',
    CHANGE: 'auth/change-password',
    VERIFY_TOKEN: 'auth/verify-token',
  },
  CREATE_PASSWORD: 'auth/reset-password',
  PASSWORD_2FA: 'auth/enrollAuthenticator',
  VALIDATE_2FA_RESET: 'users/:userId/2fa-user-validate',
};
export const COUNTRY_STATE = {
  COUNTRY: '/countries',
};

export const PRODUCTS = {
  ALL_CATEGORIES: 'categories',
  ALL_PRODUCTS: 'products',
  EXPORT: 'products/export',
};

export const API_ENDPOINT = {
  AUTHENTICATION,
  USERS: {
    LIST: 'users',
    UPDATE_STATUS: 'users/update-status',
    BULK_PASSWORD_RESET: 'users/password-reset-request',
    BULK_2FA_RESET: 'users/2fa-reset',
    DELETE: 'users/delete-users',
    UPDATE_PROFILE: 'users/update-profile',
    GET_LOGGED_IN_PROFILE: 'users/profile',
    DETAILS: 'users',
    ADD_TEAMS: 'users/:userId/add-to-team',
    UPDATE: 'users/:userId/update-detail',
    UPDATE_ROLE: 'users/update-role',
    SEND_INVITATION: 'users/invite-user',
    UPLOAD_PROFILE_IMAGE: 'users/profile-picture',
    UPLOAD_USER_PROFILE_IMAGE: 'users/:userId/profile-picture',
    EXPORT: 'users/export-users',
    ACCEPT_INVITE_USER: 'user/accept-invite',
    CHECK_EMAIL_EXIST: 'users/validate-user',
    VERIFY_TOKEN: 'users/invited-verify',
  },
  TEAMS: {
    LIST: 'teams',
    TEAM: 'team',
    MEMBERS_TO_ADD: 'team/members',
    ADD_MEMBER: 'team/add-members',
    REMOVE_MEMBER: 'team/remove-members',
    DELETE_MULTIPLE: 'teams/delete-multiple',
    EXPORT: 'teams/export/csv',
  },
  PERSONAL_SETTING: {
    LIST: 'personal-setting',
    UPDATE: 'user/personal-setting',
  },
  PASSWORD_SMS: 'personal-setting/verify-sms',
  VERIFY_CODE: 'personal-setting/verify-code',
  VERIFY_CODE_SMS: 'personal-setting/verify-code-sms',
  COUNTRY_STATE,
  PRODUCTS,
  COMPANY_REGISTER: 'company/complete-registration',
  COMPANIES: 'companies',
  COMPANY: {
    LIST: 'company',
    COMPANIES: 'companies',
    DETAILS: 'companies/profile',
  },
  COMPANY_REGISTER_RESEND: 'companies/:companyId/resend-link',
  ORDERS: '/carts',
  CONTACT_SUPPORT: '/orders/support',
  ORDER_DETAILS: '/orders',
  ORDER_ANALYTICS: '/orders/analytics',
  REORDER_PRODUCTS: '/orders/:orderId/reorder',
  ORDER_EXPORT: '/orders/export',
  PROMOTIONS: {
    LIST: 'promos',
  },
  PAYMENT: {
    DIRECT: 'stripe/payment',
    CREDIT: 'credit-payment',
    CONFIRM: 'stripe/confirm',
  },
  OVERVIEW: {
    SPENDING_SUMMARY: 'dashboard/your-spending',
    SPENDING_PER_CATEGORIES: 'dashboard/spending-by-category',
    RECENT_ORDERS: 'dashboard/recent-orders',
    FREQUENTLY_PURCHASED: 'dashboard/frequently-purchased',
    RECENTLY_ADDED_USER: 'dashboard/recent-users',
    RECENTLY_VIEWED_PRODUCTS: 'dashboard/recent-viewed-products',
    PURCHASING_METHOD: 'dashboard/purchasing-method',
    TOTAL_SPENDING: 'dashboard/total-spending',
    TOTAL_ORDERS: 'dashboard/total-orders',
    SUBMIT_FEEDBACK: 'dashboard/help-us-improve',
  },
  ADDRESS: '/address',
  SEARCH: {
    GENERAL_SEARCH: '/search',
    PRODUCT_SEARCH: '/search/products',
    ORDER_SEARCH: '/search/orders',
    TEAM_SEARCH: '/search/teams',
    USER_SEARCH: '/search/users',
  },
  NOTIFICATIONS: {
    BASE: 'notifications',
    SSE_EVENTS: 'notifications/events',
    ALL: 'notifications',
    CLEAR_ALL: 'notifications/clearAll',
  },
};
