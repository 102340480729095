import IconsComponent from '../icons/IconsComponent';
import { COLOUR_CONSTANTS } from '@/utils/constants/colour-constants';
import { NotificationType } from '@/utils/constants/notification.constants';

const NotificationIcon = ({
  notificationType,
}: {
  notificationType: NotificationType;
}) => {
  const getIconName = () => {
    switch (notificationType) {
      case NotificationType.USERS:
        return 'fill-user';

      case NotificationType.TEAMS:
        return 'fill-users';

      case NotificationType.ORDERS:
        return 'fill-cube';

      default:
        return 'reg-question';
    }
  };

  return (
    <div className='grid size-11 flex-shrink-0 place-content-center rounded-full bg-gray-200'>
      <IconsComponent
        icon={getIconName()}
        fill={COLOUR_CONSTANTS.GRAY_400}
      />
    </div>
  );
};

export default NotificationIcon;
