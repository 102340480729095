import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import {
  AllProductsApi,
  AllProductsCategoriesApi,
} from '@/services/api/products';
import {
  MediaType,
  PAGINATION_CONSTANTS,
  QUERY_CONSTANTS,
  TANSTACK_QUERY_KEY,
} from '@/utils/constants';
import { PRICETYPE } from '@/utils/constants/product-stock.constants';
import { getSearchParams } from '@/components/hooks/router/useGetSearchParams';
import { useURLSearchParams } from '@/components/hooks/router/useURLSearchParams';
import {
  isProductDynamicPricing,
  productBuyPrice,
  productPriceType,
} from '@/utils/helper/product/product-price';
import { RealTimeStockProductList } from '@/pages/real-time-stock/RealTimeStockColumn';
import shoeAsset from '@/assets/images/Image.png';

export function useGetRealTimeStockList() {
  const searchParams = new URLSearchParams();
  const { categoryId } = useParams();

  const searchParamsRecord: Record<typeof QUERY_CONSTANTS.SEARCH, string[]> =
    getSearchParams([QUERY_CONSTANTS.SEARCH]);

  const searchParamsArray: string[] =
    searchParamsRecord[QUERY_CONSTANTS.SEARCH];

  const search: string = Array.isArray(searchParamsArray)
    ? searchParamsArray[0]
    : searchParamsArray;

  const { searchValue: page } = useURLSearchParams(QUERY_CONSTANTS.PAGE);
  const { searchValue: perPage } = useURLSearchParams(QUERY_CONSTANTS.LIMIT);
  const { searchValue: priceFrom } = useURLSearchParams(
    QUERY_CONSTANTS.PRICE_RANGE.FROM,
  );
  const { searchValue: priceTo } = useURLSearchParams(
    QUERY_CONSTANTS.PRICE_RANGE.TO,
  );

  const { searchValue: stockFrom } = useURLSearchParams(
    QUERY_CONSTANTS.STOCK_RANGE.FROM,
  );
  const { searchValue: stockTo } = useURLSearchParams(
    QUERY_CONSTANTS.STOCK_RANGE.TO,
  );

  const { searchValue: sortField } = useURLSearchParams(
    QUERY_CONSTANTS.SORT.FIELD,
  );

  const { searchValue: sortOrder } = useURLSearchParams(
    QUERY_CONSTANTS.SORT.ORDER,
  );

  const {
    sizes: shoeSizes,
    shoe_color: colourHex,
    categories,
  } = getSearchParams([
    QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_SIZE,
    QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_COLOR,
    QUERY_CONSTANTS.CATEGORIES,
  ]);

  if (search) searchParams.append(QUERY_CONSTANTS.QUERY_Q, search);

  if (categoryId) searchParams.append(QUERY_CONSTANTS.CATEGORY_ID, categoryId);

  if (page) searchParams.append(QUERY_CONSTANTS.PAGE, page);

  if (perPage) {
    searchParams.append(QUERY_CONSTANTS.PAGE_SIZE, perPage);
  } else {
    searchParams.append(
      QUERY_CONSTANTS.PAGE_SIZE,
      String(PAGINATION_CONSTANTS.DEFAULT_PAGE_LIMIT),
    );
  }

  if (colourHex.length > 0) {
    colourHex.forEach((colour) => {
      searchParams.append('colours[]', colour);
    });
  }

  if (shoeSizes.length > 0) {
    shoeSizes.forEach((size) => {
      searchParams.append(
        QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_SIZE + '[]',
        size,
      );
    });
  }

  if (categories.length > 0) {
    categories.forEach((category) => {
      searchParams.append(QUERY_CONSTANTS.CATEGORIES + '[]', category);
    });
  }

  if (sortField) searchParams.append(QUERY_CONSTANTS.SORT.FIELD, sortField);

  if (sortOrder) searchParams.append(QUERY_CONSTANTS.SORT.ORDER, sortOrder);

  if (priceFrom && priceTo) {
    searchParams.append('price[min]', priceFrom.split('.')[0]);
    searchParams.append('price[max]', priceTo.split('.')[0]);
  }

  if (stockFrom && stockTo) {
    searchParams.append('stock[min]', stockFrom);
    searchParams.append('stock[max]', stockTo);
  }

  const queryString = searchParams.toString();
  const url = queryString ? `?${queryString}` : '';

  return useQuery({
    queryKey: [
      TANSTACK_QUERY_KEY.PRODUCTS.ALL_PRODUCTS,
      search,
      page,
      perPage,
      priceFrom,
      priceTo,
      colourHex,
      shoeSizes,
      stockFrom,
      stockTo,
      categories,
      sortField,
      sortOrder,
    ],

    queryFn: () => AllProductsApi.getAllProducts(url)(),
    select: ({ data: products, filterable, meta, stock }) => {
      const { total, page: currentPage, pageCount } = meta.pagination;

      const productList: RealTimeStockProductList[] = products.map(
        (product) => ({
          id: product.id,
          categoryId: product.categoryId,
          sku: product.skuCode,
          name: product.name,
          color: {
            name: product.colourName,
            hex: product.colourHex,
          },
          image: {
            src: product.medias
              ? (product.medias.find(
                  (media) => media.type === MediaType.THUMBNAIL,
                )?.url ?? shoeAsset)
              : '',
            alt: `${product.name} image`,
          },
          size: product.variants?.map((variant) => String(variant.size)) ?? [],
          category: product.category?.name ?? 'N/A',
          stock: product.stock,
          isDynamicPricing: isProductDynamicPricing(product.prices),
          buyPrice: productBuyPrice(product.prices),
          rrp: Number(product.price),
          priceType: productPriceType(product.prices) as PRICETYPE,
          updatedAt: product.updatedAt,
          stockUpdatedAt: product.stockUpdatedAt,
        }),
      );

      return {
        currentPage,
        totalProducts: total,
        totalPages: pageCount,
        filterable,
        productList,
        lastUpdateDate: stock.updatedAt,
      };
    },
  });
}

export function useGetCategoriesFilterOptions() {
  return useQuery({
    queryKey: [TANSTACK_QUERY_KEY.PRODUCTS.ALL_PRODUCTS_CATEGORY],
    queryFn: () => {
      return AllProductsCategoriesApi.getAllCategories();
    },
    select: ({ data }) => {
      const categories = data.map((category) => ({
        label: category.name,
        value: category.name,
      }));
      return { categories };
    },
  });
}
