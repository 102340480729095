import { cn } from '@/utils/cn';

export const SingleColumnSkeleton = ({
  noOfCols = 1,
  className,
  height = 16,
}: {
  noOfCols: number;
  className?: string;
  height?: number;
}) => {
  return (
    <div
      role='status'
      className={cn('w-full animate-pulse divide-y', className)}
    >
      {Array.from({ length: noOfCols }, (_, i) => (
        <div
          key={i}
          className='flex items-center gap-x-1 py-7'
        >
          <div className={`w-full bg-gray-300 h-${height}`} />
        </div>
      ))}
    </div>
  );
};
