import React from 'react';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import {
  useAlert,
  useGetLoggedInUser,
  useGetLoggedInUserCompanySetting,
  useUserLogin,
} from '@/components/hooks';
import { ROUTES } from '@/utils/constants';
import { useUserStore } from '@/store/user-store-new';
import { handleApiError, isEmpty } from '@/utils/helper';
import { LoginStep } from '@/utils/constants/auth.constants';
import { LoginFormSchema, TLoginFormSchema } from '@/schemas';
import useNotificationStore from '@/store/notification.store';
import { IconSpinner } from '@/components/ui/icons/regular/spinner';
import { TSystemCurrencyFormats } from '@/utils/constants/general.constant';
import { Checkbox, Button, Label, Input, PasswordInput } from '@/components';

export interface ErrorResponse {
  payload: {
    error_description?: string;
  };
}

export interface SetLoginStep {
  setLoginStep: React.Dispatch<React.SetStateAction<LoginStep>>;
}

export const LoginPage = ({ setLoginStep }: SetLoginStep) => {
  const { showAlert } = useAlert();
  const { setAuth } = useUserStore();

  const { setUser, removeStorage, setSystemDefault } = useUserStore();
  const { v12SSEEventCount, setv12SSEEventCount } = useNotificationStore();

  const loginMethod = useForm<TLoginFormSchema>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    resolver: zodResolver(LoginFormSchema),
  });

  const { handleSubmit, setValue, watch } = loginMethod;
  const rememberMe = watch('rememberMe');

  const { refetch: refetchLoggedInUserDetail } = useGetLoggedInUser();
  const { refetch: refetchLoggedInUserCompanySetting } =
    useGetLoggedInUserCompanySetting();

  const { mutate: handleLogin, isPending: isLoginLoading } = useUserLogin();

  const onSubmit: SubmitHandler<TLoginFormSchema> = (loginData) => {
    handleLogin(loginData, {
      onSuccess: async ({ data }) => {
        const {
          role,
          hasUserDetail,
          isTwoFactorSet,
          mfaType,
          token,
          skipTwoFactor,
        } = data;
        setAuth({ role, hasUserDetail, isTwoFactorSet, mfaType, token });

        if (!isTwoFactorSet && !hasUserDetail && !skipTwoFactor) {
          setLoginStep(LoginStep.PERSONAL_SETTINGS);
          return;
        }

        if (
          isEmpty(mfaType) &&
          hasUserDetail &&
          isTwoFactorSet &&
          !skipTwoFactor
        ) {
          setLoginStep(LoginStep.TWOFA);
          return;
        }

        if (!isTwoFactorSet && hasUserDetail && isEmpty(mfaType)) {
          setLoginStep(LoginStep.TWOFA);
          return;
        }

        if (
          isTwoFactorSet &&
          !skipTwoFactor &&
          hasUserDetail &&
          !isEmpty(mfaType)
        ) {
          setLoginStep(LoginStep.VERIFY);
          return;
        }

        if (hasUserDetail && isTwoFactorSet && skipTwoFactor) {
          const [userDetails, companySetting] = await Promise.all([
            refetchLoggedInUserDetail(),
            refetchLoggedInUserCompanySetting(),
          ]);

          if (userDetails.isSuccess) {
            setUser({ ...userDetails.data.data });
            setAuth({ ...data, valid: true });
          } else {
            removeStorage();
          }

          if (companySetting.isSuccess) {
            setSystemDefault({ ...companySetting.data });
            setv12SSEEventCount({
              ...v12SSEEventCount,
              currency: companySetting.data.currency as TSystemCurrencyFormats,
              dateFormat: companySetting.data.dateFormat,
              timeFormat: companySetting.data.timeFormat,
            });
          }

          return;
        }
      },

      onError: (error) => {
        showAlert(handleApiError(error), 'error');
      },
    });
  };

  return (
    <div className='flex w-full flex-col p-2 sm:max-w-[496px] md:p-10'>
      <h4 className='mb-10'>Login to your account</h4>
      <FormProvider {...loginMethod}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='space-y-4 md:w-[416px]'
        >
          <div className='space-y-1'>
            <Label htmlFor='email'>Email</Label>
            <Input
              type='email'
              name='email'
              autoComplete='username'
              placeholder='Enter your email'
            />
          </div>
          <div className='space-y-1'>
            <Label htmlFor='password'>Password</Label>
            <PasswordInput<TLoginFormSchema>
              name='password'
              placeholder='Enter your password'
              errorFieldName='password'
              autoComplete='current-password'
              showPasswordTooltip={false}
            />
          </div>
          <div className='flex items-start gap-2 pt-2'>
            <Checkbox
              checked={rememberMe}
              onChange={(e) => setValue('rememberMe', e.target.checked)}
            />
            <span className='mb-3 text-sm font-normal text-gray-800'>
              Keep me logged in for 30 days
            </span>
          </div>
          <Button
            className='mb-6 w-full'
            type='submit'
            variant={'primary'}
            disabled={isLoginLoading}
            data-cy='login-button'
          >
            {isLoginLoading && (
              <IconSpinner
                fill='white'
                size={24}
              />
            )}
            Login
          </Button>
        </form>
      </FormProvider>
      <Link
        to={`/${ROUTES.FORGOT_PASSWORD.FORGOT_PASSWORD}`}
        className='py-8 text-center font-semibold text-gray-600'
      >
        Forgot Password?
      </Link>
      <hr />
      <div className='mt-6 flex flex-col items-center gap-2 text-center sm:flex-row'>
        <p className='text-base text-gray-500'>
          Don’t have a distributor account?&nbsp;
        </p>
        <Link
          to={`/${ROUTES.REGISTER.REGISTER}`}
          className='text-base font-semibold text-primary-500'
        >
          Create New
        </Link>
      </div>
    </div>
  );
};
