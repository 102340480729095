import { z } from 'zod';

export const searchSchema = z.string().optional();
export type TSearch = z.infer<typeof searchSchema>;

export const searchInput = z.object({
  search: z.string(),
});

export type TSearchInput = z.infer<typeof searchInput>;

export const GeneralSearchParamsSchema = z.object({
  q: z.string().optional(),
  page: z.string().optional(),
  pageSize: z.string().optional(),
});

export type TGeneralSearchParams = z.infer<typeof GeneralSearchParamsSchema>;

export const ProductSchema = z.object({
  id: z.string().uuid().nullable(),
  name: z.string().nullable().optional(),
  skuCode: z.string().nullable().optional(),
  categoryId: z.string().nullable().optional(),
  colourName: z.string().nullable().optional(),
  colourHex: z.string().nullable().optional(),
  media: z
    .object({
      id: z.string().uuid().nullable(),
      type: z.string().nullable(),
      sort: z.number().nullable().optional(),
      altText: z.string().nullable().optional(),
      url: z.string().nullable(),
    })
    .nullable()
    .optional(),
  price: z.string().nullable().optional(),
});

export type TProductSchema = z.infer<typeof ProductSchema>;
export const OrderSchema = z.object({
  id: z.string().uuid().nullable(),
  customId: z.string().nullable().optional(),
  updatedAt: z.string().nullable().optional(),
  items: z.array(
    z.object({
      id: z.string().uuid().nullable(),
      quantity: z.number().nullable().optional(),
      product: ProductSchema,
    }),
  ),
});

export type TOrderSchema = z.infer<typeof OrderSchema>;

export const TeamSchema = z.object({
  id: z.string().uuid().nullable(),
  name: z.string().nullable(),
  email: z.string().email().nullable().optional(),
  users: z
    .array(
      z.object({
        id: z.string().uuid().nullable(),
        firstName: z.string().nullable().optional(),
        lastName: z.string().nullable().optional(),
        avatar: z.string().nullable().optional(),
      }),
    )
    .optional(),
});

export type TTeamSchema = z.infer<typeof TeamSchema>;

export const UserSchema = z.object({
  id: z.string().uuid().nullable(),
  firstName: z.string().nullable().optional(),
  lastName: z.string().nullable().optional(),
  designation: z.string().nullable().optional(),
  email: z.string().email().nullable().optional(),
  avatar: z.string().nullable().optional(),
  teams: z
    .array(
      z.object({
        id: z.string().uuid().nullable(),
        name: z.string().nullable(),
      }),
    )
    .nullable()
    .optional(),
});

export type TUserSchema = z.infer<typeof UserSchema>;

export const MetaSchema = z.object({
  pagination: z.object({
    page: z.number().nullable().optional(),
    pageSize: z.number().nullable().optional(),
    total: z.number().nullable().optional(),
    pageCount: z.number().nullable().optional(),
  }),
});

export type TMetaSchema = z.infer<typeof MetaSchema>;

export const SearcDataSchema = z.object({
  orders: z.array(OrderSchema.optional()),
  products: z.array(ProductSchema.optional()).optional(),
  users: z.array(UserSchema.optional()),
  teams: z.array(TeamSchema.optional()),
});

export type TSearcDataSchema = z.infer<typeof SearcDataSchema>;

export const GeneralSearchRequestSchema = z.void();
export type TGeneralSearchRequestSchema = z.infer<
  typeof GeneralSearchRequestSchema
>;

export const GeneralSearchResponseSchema = z.object({
  data: SearcDataSchema,
  message: z.string().nullable(),
  timestamp: z.string().nullable(),
  meta: z
    .object({
      product: z.object({
        count: z.number().nullable().optional(),
      }),
      order: z.object({
        count: z.number().nullable().optional(),
      }),
      user: z.object({
        count: z.number().nullable().optional(),
      }),
      team: z.object({
        count: z.number().nullable().optional(),
      }),
    })
    .optional(),
});

// Type inference
export type TGeneralSearchResponseSchema = z.infer<
  typeof GeneralSearchResponseSchema
>;

export const ProductSearchResponseSchema = z.object({
  data: z.array(ProductSchema).optional(),
  meta: MetaSchema.optional(),
  message: z.string().nullable().optional(),
  timestamp: z.string().nullable().optional(),
});

export type TProductSearchResponseSchema = z.infer<
  typeof ProductSearchResponseSchema
>;

export const OrderSearchResponseSchema = z.object({
  data: z.array(OrderSchema).optional(),
  meta: MetaSchema.optional(),
  message: z.string().nullable().optional(),
  timestamp: z.string().nullable().optional(),
});

export type TOrderSearchResponseSchema = z.infer<
  typeof OrderSearchResponseSchema
>;

export const UserSearchResponseSchema = z.object({
  data: z.array(UserSchema).optional(),
  meta: MetaSchema.optional(),
  message: z.string().nullable().optional(),
  timestamp: z.string().nullable().optional(),
});

export type TUserSearchResponseSchema = z.infer<
  typeof UserSearchResponseSchema
>;

export const TeamSearchResponseSchema = z.object({
  data: z.array(TeamSchema).optional(),
  meta: MetaSchema.optional(),
  message: z.string().nullable().optional(),
  timestamp: z.string().nullable().optional(),
});

export type TTeamSearchResponseSchema = z.infer<
  typeof TeamSearchResponseSchema
>;
