import { Agent, hashComponents, load } from '@fingerprintjs/fingerprintjs';

export class FingerprintGenerator {
  private fpPromise: Promise<Agent>;

  private fingerprint: string | null = null;

  constructor() {
    this.fpPromise = load();
  }

  public async generateFingerprint(): Promise<string> {
    try {
      const fp = await this.fpPromise;
      const result = await fp.get();
      const { screenResolution, screenFrame, ...components } =
        result.components;
      this.fingerprint = hashComponents(components);
      return this.fingerprint;
    } catch (error) {
      console.error('Error generating fingerprint:', error);
      throw error;
    }
  }

  public async getFingerprint(): Promise<string> {
    if (this.fingerprint) {
      return this.fingerprint;
    }

    return this.generateFingerprint();
  }

  public async matchFingerprint(fingerprint: string): Promise<boolean> {
    const currentFingerprint = await this.getFingerprint();
    return currentFingerprint === fingerprint;
  }
}

export const fingerprintGenerator = new FingerprintGenerator();
