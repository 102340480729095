import { useMutation } from '@tanstack/react-query';

import { TANSTACK_QUERY_KEY } from '@/utils/constants/query-key.constant';
import { NotificationAPI } from '@/services/api/notifications/notifications';

const useMarkNotificationAsRead = () => {
  return useMutation({
    mutationKey: [TANSTACK_QUERY_KEY.NOTIFICATION.MARK_AS_READ],
    mutationFn: (notificationId: string) =>
      NotificationAPI.markNotificationAsRead(notificationId)(),
  });
};

export default useMarkNotificationAsRead;
