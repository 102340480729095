import React, { useState } from 'react';

import { Button, IconsComponent } from '@/components';

export interface SlideInPanelProps {
  direction?: 'left' | 'right';
  children?: React.ReactNode;
  input?: React.ReactNode;
}

export const SlideInPanel: React.FC<SlideInPanelProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const buttonText = isOpen ? 'Close Filter' : 'Filter by';

  const iconType = isOpen ? 'reg-close' : 'reg-filter';

  return (
    <>
      <Button
        size='xs'
        variant='gray'
        intent='tertiary'
        className='px-3'
        onClick={() => setIsOpen((previousState) => !previousState)}
      >
        <IconsComponent
          fill='gray'
          icon={iconType}
          size='md'
        />
        {buttonText}
      </Button>
      <div
        className={`transition-all ${isOpen ? 'ml-auto flex space-x-2 animate-ease-in-out' : 'hidden animate-slide-out'}`}
      >
        {children}
      </div>
    </>
  );
};
