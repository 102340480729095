import { z } from 'zod';

import {
  BulkExportTeamRequestSchema,
  BulkExportTeamResponseSchema,
  CreateTeamRequestSchema,
  CreateTeamResponseSchema,
  EditTeamSchema,
  GetAddedMemberResSchema,
  GetMembersRequestSchema,
  GetMembersResponseSchema,
  GetRequestSingleTeamRequestSchema,
  GetTeamsRequestSchema,
  GetTeamsResponseSchema,
  GetTeamsSingleResponseSchema,
  TeamAddMembersResponseSchema,
  TeamAddMembersSchema,
  TeamDetailsResponseSchema,
} from '@/schemas';
import {
  DeleteBulkTeamRequestSchema,
  DeleteBulkTeamResponseSchema,
  // DeleteTeamRequestSchema,
  DeleteTeamResponseSchema,
} from '@/schemas/team/deleteTeam.schema';
import { api } from '@/utils/helper';
import {
  DeleteMemberOnTeamRequestSchema,
  DeleteMemberOnTeamResponseSchema,
} from '@/schemas/team/userDeleteOnTeamSchema';
import { API_ENDPOINT } from '@/utils/constants';
import { deleteApi } from '@/utils/helper/api/delete.helpers';
import { apiGetAll } from '@/utils/helper/api/getApi.helpers';

// get all teams

const getAllTeams = (path: string) =>
  api<
    z.infer<typeof GetTeamsRequestSchema>,
    z.infer<typeof GetTeamsResponseSchema>
  >({
    method: 'GET',
    path,
    requestSchema: GetTeamsRequestSchema,
    responseSchema: GetTeamsResponseSchema,
  });

const GetTeamsRequestSchemaAllList = z.object({
  limit: z.number(),
  page: z.number(),
  sortOrder: z.enum(['asc', 'desc']),
  q: z.string().optional(),
  sortField: z.string().optional(),
});
const getAllTeamsList = apiGetAll<
  z.infer<typeof GetTeamsRequestSchemaAllList>,
  z.infer<typeof GetTeamsResponseSchema>
>({
  method: 'GET',
  path: API_ENDPOINT.TEAMS.LIST,
  requestSchema: GetTeamsRequestSchemaAllList,
  responseSchema: GetTeamsResponseSchema,
});

//get all member to add in team
const getAllMemberToADD = ({ teamId }: { teamId: string }) =>
  api<
    z.infer<typeof GetMembersRequestSchema>,
    z.infer<typeof GetMembersResponseSchema>
  >({
    method: 'GET',
    path: `${API_ENDPOINT.TEAMS.LIST}/${teamId}/available-members`,
    requestSchema: GetMembersRequestSchema,
    responseSchema: GetMembersResponseSchema,
  });

// add member to team
const addMemberToTeam = (id: string) =>
  api<
    z.infer<typeof TeamAddMembersSchema>,
    z.infer<typeof TeamAddMembersResponseSchema>
  >({
    method: 'POST',
    path: `${API_ENDPOINT.TEAMS.LIST}/${id}/members`,
    requestSchema: TeamAddMembersSchema,
    responseSchema: TeamAddMembersResponseSchema,
  });

// create team
const createTeam = () =>
  api<
    z.infer<typeof CreateTeamRequestSchema>,
    z.infer<typeof CreateTeamResponseSchema>
  >({
    method: 'POST',
    path: API_ENDPOINT.TEAMS.LIST,
    requestSchema: CreateTeamRequestSchema,
    responseSchema: CreateTeamResponseSchema,
  });

// added member to team
const addedMemberToTeam = ({ teamId }: { teamId: string }) =>
  api<
    z.infer<typeof GetMembersRequestSchema>,
    z.infer<typeof GetAddedMemberResSchema>
  >({
    method: 'GET',
    path: `${API_ENDPOINT.TEAMS.LIST}/${teamId}/members`,
    requestSchema: GetMembersRequestSchema,
    responseSchema: GetAddedMemberResSchema,
  });

// delete member from team
const deleteMemberOnTeam = (id: string) => {
  return deleteApi<
    z.infer<typeof DeleteMemberOnTeamRequestSchema>,
    z.infer<typeof DeleteMemberOnTeamResponseSchema>
  >({
    method: 'DELETE',
    path: `${API_ENDPOINT.TEAMS.LIST}/${id}/members`,
    requestSchema: DeleteMemberOnTeamRequestSchema,
    responseSchema: DeleteMemberOnTeamResponseSchema,
  });
};

// get specific team details
const getTeamDetails = api<
  z.infer<typeof GetRequestSingleTeamRequestSchema>,
  z.infer<typeof GetTeamsSingleResponseSchema>
>({
  method: 'GET',
  path: API_ENDPOINT.TEAMS.LIST,
  requestSchema: GetRequestSingleTeamRequestSchema,
  responseSchema: GetTeamsSingleResponseSchema,
});

// edit team
const editTeam = (teamId: string) =>
  api<
    z.infer<typeof EditTeamSchema>,
    z.infer<typeof TeamDetailsResponseSchema>
  >({
    method: 'PATCH',
    path: `${API_ENDPOINT.TEAMS.LIST}/${teamId}`,
    requestSchema: EditTeamSchema,
    responseSchema: TeamDetailsResponseSchema,
  });

// delete team
const deleteTeam = api<
  z.infer<typeof DeleteBulkTeamRequestSchema>,
  z.infer<typeof DeleteTeamResponseSchema>
>({
  method: 'DELETE',
  path: API_ENDPOINT.TEAMS.LIST,
  requestSchema: DeleteBulkTeamRequestSchema,
  responseSchema: DeleteTeamResponseSchema,
});

// delete Bulk team
const deleteBulkTeam = deleteApi<
  z.infer<typeof DeleteBulkTeamRequestSchema>,
  z.infer<typeof DeleteBulkTeamResponseSchema>
>({
  method: 'DELETE',
  path: API_ENDPOINT.TEAMS.LIST,
  requestSchema: DeleteBulkTeamRequestSchema,
  responseSchema: DeleteBulkTeamResponseSchema,
});

// bulk export team
const bulkExportTeam = api<
  z.infer<typeof BulkExportTeamRequestSchema>,
  z.infer<typeof BulkExportTeamResponseSchema>
>({
  method: 'POST',
  path: API_ENDPOINT.TEAMS.EXPORT,
  requestSchema: BulkExportTeamRequestSchema,
  responseSchema: BulkExportTeamResponseSchema,
});

export const TeamAPI = {
  getAllTeams,
  getAllTeamsList,
  getTeamDetails,
  createTeam,
  editTeam,
  deleteTeam,
  getAllMemberToADD,
  addMemberToTeam,
  addedMemberToTeam,
  deleteMemberOnTeam,
  deleteBulkTeam,
  bulkExportTeam,
};
