import { create } from 'zustand';

import {
  NotificationTypes,
  NotificationPageType,
  CategorizedNotificationsMap,
} from '@/utils/constants/notification.constants';
import {
  TSystemCurrencyFormats,
  TSystemDateFormats,
  TSystemTimeFormats,
} from '@/utils/constants/general.constant';
import { TSingleNotificationResponseSchema } from '@/schemas/api/notifications/notifications';

export interface V12SSEEventInterface {
  cartItemCount: number;
  currency: TSystemCurrencyFormats | null;
  dateFormat: TSystemDateFormats | null;
  notificationCount: number;
  timeFormat: TSystemTimeFormats | null;
}

interface NotificationInterface {
  showNotificationModal: boolean;
  activeNotificationTab: NotificationTypes;
  activeNotificationPage: NotificationTypes;
  clearNotificationDialogOpen: boolean;
  isAllNotificationEmpty: boolean;
  isUnreadNotificationEmpty: boolean;
  // modal notifications
  allFormattedModalNotifications: CategorizedNotificationsMap<string>;
  unreadFormattedModalNotifications: TSingleNotificationResponseSchema[];
  // page notifications
  allPageNotifications: TSingleNotificationResponseSchema[];
  unreadPageNotifications: TSingleNotificationResponseSchema[];
  allFormattedPageNotifications: CategorizedNotificationsMap<string>;
  unreadFormattedPageNotifications: CategorizedNotificationsMap<string>;
  // badge count
  v12SSEEventCount: V12SSEEventInterface;
  isNotificationAPILoading: boolean;
}

interface NotificationInterfaceActions {
  setShowNotificationModal: (showNotificationModal: boolean) => void;
  setActiveNotificationTab: (
    type: NotificationPageType,
    value: NotificationTypes,
  ) => void;
  setIsNotificationEmpty: (
    type: NotificationTypes,
    isNotificationEmpty: boolean,
  ) => void;
  setClearNotificationDialogOpen: (
    clearNotificationDialogOpen: boolean,
  ) => void;
  // set all modal notifications
  setAllFormattedModalNotifications: (
    allFormattedModalNotifications: CategorizedNotificationsMap<string>,
  ) => void;
  setUnreadFormattedModalNotifications: (
    unreadFormattedModalNotifications: TSingleNotificationResponseSchema[],
  ) => void;
  // set all page notifications
  setAllPageNotifications: (
    allPageNotifications: TSingleNotificationResponseSchema[],
  ) => void;
  setAllFormattedPageNotifications: (
    allFormattedPageNotifications: CategorizedNotificationsMap<string>,
  ) => void;
  setUnreadPageNotifications: (
    unreadPageNotifications: TSingleNotificationResponseSchema[],
  ) => void;
  setUnreadFormattedPageNotifications: (
    unreadFormattedPageNotifications: CategorizedNotificationsMap<string>,
  ) => void;
  setv12SSEEventCount: (v12SSEEventCount: {
    cartItemCount: number;
    currency: TSystemCurrencyFormats | null;
    dateFormat: TSystemDateFormats | null;
    notificationCount: number;
    timeFormat: TSystemTimeFormats | null;
  }) => void;
  resetNotificationStore: (isCurrentPathNotification?: boolean) => void;
  setIsNotificationAPILoading: (isNotificationAPILoading: boolean) => void;
}

const initialState: NotificationInterface = {
  showNotificationModal: false,
  activeNotificationTab: 'all',
  activeNotificationPage: 'all',
  clearNotificationDialogOpen: false,
  allFormattedModalNotifications: {},
  unreadFormattedModalNotifications: [],
  allPageNotifications: [],
  unreadPageNotifications: [],
  isAllNotificationEmpty: false,
  isUnreadNotificationEmpty: false,
  allFormattedPageNotifications: {},
  unreadFormattedPageNotifications: {},
  v12SSEEventCount: {
    cartItemCount: 0,
    currency: null,
    dateFormat: null,
    notificationCount: 0,
    timeFormat: null,
  },
  isNotificationAPILoading: false,
};

type TNotificationStoreType = NotificationInterface &
  NotificationInterfaceActions;

const useNotificationStore = create<TNotificationStoreType>((set) => ({
  ...initialState,
  setShowNotificationModal: (showNotificationModal: boolean) => {
    set({ showNotificationModal });
  },
  setActiveNotificationTab: (type, value) => {
    if (type === 'tab') set({ activeNotificationTab: value });
    else set({ activeNotificationPage: value });
  },
  setClearNotificationDialogOpen: (clearNotificationDialogOpen) => {
    set({ clearNotificationDialogOpen });
  },
  setIsNotificationEmpty: (type, isNotificationEmpty) => {
    if (type === 'all') set({ isAllNotificationEmpty: isNotificationEmpty });
    else set({ isUnreadNotificationEmpty: isNotificationEmpty });
  },
  setAllFormattedModalNotifications: (allFormattedModalNotifications) =>
    set({ allFormattedModalNotifications }),
  setUnreadFormattedModalNotifications: (unreadFormattedModalNotifications) => {
    set({ unreadFormattedModalNotifications });
  },
  setAllPageNotifications: (allPageNotifications) => {
    set({ allPageNotifications });
  },
  setUnreadPageNotifications: (unreadPageNotifications) => {
    set({ unreadPageNotifications });
  },
  setv12SSEEventCount: (v12SSEEventCount) => {
    set({ v12SSEEventCount });
  },
  setAllFormattedPageNotifications: (allFormattedPageNotifications) => {
    set({ allFormattedPageNotifications });
  },
  setUnreadFormattedPageNotifications: (unreadFormattedPageNotifications) => {
    set({ unreadFormattedPageNotifications });
  },
  setIsNotificationAPILoading: (isNotificationAPILoading) => {
    set({ isNotificationAPILoading });
  },
  resetNotificationStore: (isCurrentPathNotification = false) =>
    set(
      Object.fromEntries(
        Object.entries(initialState).filter(([key]) => {
          const alwaysExclude = [
            'showNotificationModal',
            'v12SSEEventCount',
            'activeNotificationTab',
            'activeNotificationPage',
          ];

          const conditionalExclude = [
            'allPageNotifications',
            'unreadPageNotifications',
            'allFormattedPageNotifications',
            'unreadFormattedPageNotifications',
          ];

          if (alwaysExclude.includes(key)) return false;
          if (isCurrentPathNotification && conditionalExclude.includes(key))
            return false;

          return true;
        }),
      ),
    ),
}));

export default useNotificationStore;
