import { useNavigate } from 'react-router-dom';

import { cn } from '@/utils/cn';
import useNotificationStore from '@/store/notification.store';
import { NOTIFICATION_TABS } from '@/utils/constants/notification.constants';

const NotificationTabs = ({
  className,
  isPathNotification = false,
  isNotificationEmpty,
}: {
  className?: string;
  isPathNotification?: boolean;
  isNotificationEmpty?: boolean;
}) => {
  const navigate = useNavigate();

  const {
    activeNotificationTab,
    activeNotificationPage,
    setActiveNotificationTab,
    v12SSEEventCount,
  } = useNotificationStore();

  return (
    <div className={cn('flex gap-6', className)}>
      {NOTIFICATION_TABS.map((tab) => (
        <button
          key={tab.value}
          onClick={() => {
            if (isPathNotification) {
              navigate(`/notifications?tab=${tab.value}`);
            }
            setActiveNotificationTab(
              isPathNotification ? 'page' : 'tab',
              tab.value,
            );
          }}
          className={cn(
            'border-b-2 border-transparent disabled:cursor-not-allowed',
            (isPathNotification
              ? activeNotificationPage
              : activeNotificationTab) === tab.value
              ? 'border-gray-800 text-gray-800'
              : 'text-gray-500',
            tab.value === 'all' ? 'px-3' : 'px-0',
          )}
          disabled={isNotificationEmpty}
        >
          <div className='p-2 text-base font-medium'>
            {tab.name}
            {tab.value === 'unread' &&
              v12SSEEventCount.notificationCount > 0 && (
                <span className='ml-2 rounded-md bg-gray-100 p-2 text-xs text-gray-800'>
                  {v12SSEEventCount.notificationCount > 99
                    ? '99+'
                    : v12SSEEventCount.notificationCount}
                </span>
              )}
          </div>
        </button>
      ))}
    </div>
  );
};

export default NotificationTabs;
