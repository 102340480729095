import { useMutation } from '@tanstack/react-query';

import { NotificationAPI } from '@/services/api/notifications/notifications';

const useClearAllNotifications = () => {
  return useMutation({
    mutationFn: () => NotificationAPI.clearAllNotifications(),
  });
};

export default useClearAllNotifications;
