import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ChartOptions,
} from 'chart.js';
import React from 'react';
import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

export interface ChartDataItem {
  data: (number | null)[];
  borderColor: string;
  shadowColor: string;
  zIndex: number;
  label: string; // Added label for unique dataset identification
}

export interface ChartProps {
  items: ChartDataItem[];
  color: string;
  showScales: boolean;
  showShadow: boolean;
  bars: number;
  width?: string;
  height?: string;
  options?: ChartOptions<'line' | 'bar'>; // Specific to 'line' and 'bar'
  labels?: string[];
  chartType?: 'line' | 'bar'; // New prop to determine chart type
  displayLegend?: boolean;
}

const ChartComponent: React.FC<ChartProps> = ({
  items,
  showScales,
  showShadow,
  width = '261px',
  height = '101px',
  options = {},
  labels = [
    'DataOne',
    'DataTwo',
    'DataThree',
    'DataFour',
    'DataFive',
    'DataSix',
  ],
  chartType = 'line',
  displayLegend = false,
}) => {
  //* chart configuration is actually done in chartOptions.helper.ts
  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: displayLegend, // Display legend
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        display: showScales,
      },
      y: {
        display: showScales,
        beginAtZero: true, // This ensures the y-axis starts at 0
      },
    },
    elements: {
      point: {
        radius: 0, // Points only for line charts
      },
      line: {
        tension: 0,
        borderWidth: 1,
        spanGaps: false, // Don't span gaps in data
      },
    },
  };

  const mergedOptions = { ...defaultOptions, ...options };

  const datasets = items.map((item) => ({
    fill: showShadow,
    label: item.label, // Ensure unique labels
    data: item.data,
    borderColor: item.borderColor,
    backgroundColor: item.shadowColor,
    zIndex: item.zIndex,
  }));

  const data = {
    labels,
    datasets,
  };

  return (
    <div
      style={{
        width: width, // Set fixed dimensions for the chart container
        height: height, // Set fixed dimensions for the chart container
      }}
      data-cy={`${chartType} chart`}
    >
      {chartType === 'line' ? (
        <Line
          options={mergedOptions}
          data={data}
        />
      ) : (
        <Bar
          options={mergedOptions}
          data={data}
        />
      )}
    </div>
  );
};

export default ChartComponent;
