import ErrorImg from '@/assets/images/notfound.png';
import { Button } from '@/components';
import NoResult from '@/components/ui/no-result/NoResult';
import { ROUTES } from '@/utils/constants';
import { useNavigate } from 'react-router-dom';

export const NoSearchResultFound = ({
  searchValue,
  className,
  buttonEnabled = true,
}: {
  searchValue: string;
  className?: string;
  buttonEnabled?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <div className='p-4'>
      <NoResult
        desc={`No worries! We might not have exactly what you searched for, '${searchValue}', but we have a wide selection of products. Try browsing our categories or using different keywords.`}
        imageSrc={ErrorImg}
        classes={className ? className : ''}
        title={"Hmm, Didn't Find What You Were Looking For?"}
      >
        {buttonEnabled && (
          <Button
            intent='solid'
            variant={'primary'}
            onClick={() => navigate(`/${ROUTES.PRODUCT.ALL_PRODUCTS}`)}
          >
            Explore Products
          </Button>
        )}
      </NoResult>
    </div>
  );
};
