import { TSingleNotificationResponseSchema } from '@/schemas/api/notifications/notifications';

export const NOTIFICATION_TABS = [
  { name: 'All', value: 'all' },
  { name: 'Unread', value: 'unread' },
] as const;

export type NotificationTypes = 'all' | 'unread';
export type NotificationPageType = 'tab' | 'page';

export enum NotificationType {
  TEAMS = 'TEAMS',
  PRODUCTS = 'PRODUCTS',
  PROMOS = 'PROMOS',
  USERS = 'USERS',
  SPECIAL_PRICES = 'SPECIAL_PRICES',
  ORDERS = 'ORDERS',
}

export enum NotificationActionType {
  TEAM_CREATED = 'TEAM_CREATED',
  TEAM_UPDATED = 'TEAM_UPDATED',
  TEAM_DELETED = 'TEAM_DELETED',
  USER_ADDED_TO_TEAM = 'USER_ADDED_TO_TEAM',
  USER_REMOVED_FORM_TEAM = 'USER_REMOVED_FORM_TEAM',
  USER_REMOVED = 'USER_REMOVED',
  USER_INVITED = 'USER_INVITED',
  USER_REGISTRATION_COMPLETED = 'USER_REGISTRATION_COMPLETED',
  DUE = 'DUE',
  PAID = 'PAID',
  PLACE_ON_ACCOUNT = 'PLACE_ON_ACCOUNT',
  PRODUCT_CREATED = 'PRODUCT_CREATED',
  PROMO_CREATED = 'PROMO_CREATED',
  SPECIAL_PRICE_CREATED = 'SPECIAL_PRICE_CREATED',
}

export type NotificationCategory =
  | 'Today'
  | 'Yesterday'
  | 'This_Month'
  | 'Older';

export type CategorizedNotificationsMap<T extends string> = Record<
  T,
  TSingleNotificationResponseSchema[]
>;
