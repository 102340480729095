import { useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { cn } from '@/utils/cn';
import { useSidebarStore } from '@/store';
import { ROUTES } from '@/utils/constants';
import { getUserFullName } from '@/utils/helper';
import { useUserStore } from '@/store/user-store-new';
import HeaderNotification from './headerNotifications';
import useNotificationStore from '@/store/notification.store';

import svgCollapsedIcon from '@/assets/icons/sidebarCollapsed.svg';
import { LogoutModal } from '@/pages/authentication/logout/LogoutModal';
import { Avatar, Button, IconsComponent, ImageComponent } from '@/components';
import { useIsDeviceTabletOrLarger, useOutsideClick } from '@/components/hooks';
import chevronDownUserProfileHeader from '@/assets/icons/chevronDownUserProfileHeader.svg';
import HeaderSearchBox from '@/pages/search/components/header-search';

const SidebarTogglerIcon = () => {
  const { sidebarIconOnlyViewToggler, sidebarVisibilityToggler } =
    useSidebarStore();
  const isTabletOrLarger: boolean = useIsDeviceTabletOrLarger();

  const handleHeaderSidebarIconClick = () =>
    !isTabletOrLarger
      ? sidebarVisibilityToggler()
      : sidebarIconOnlyViewToggler();

  return (
    <div
      className='py-4 pl-1 pr-1 sm:px-3.5'
      data-cy='header-sidebar'
    >
      <button
        className='h-[40px] w-[40px] rounded-full bg-gray-100 sm:h-[44px] sm:w-[44px]'
        onClick={handleHeaderSidebarIconClick}
      >
        <div className='grid h-full place-content-center'>
          <ImageComponent
            src={svgCollapsedIcon}
            alt='Sidebar Collapse Icon'
          />
        </div>
      </button>
    </div>
  );
};

const UserProfile = () => {
  const { user } = useUserStore();

  const userProfileNestedMenuRef = useRef<HTMLDivElement>(null);

  const [userProfileNestedMenu, setUserProfileNestedMenu] =
    useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);

  const toggleUserProfileMenu = () => {
    setUserProfileNestedMenu(!userProfileNestedMenu);
  };

  useOutsideClick(userProfileNestedMenuRef, () => {
    setUserProfileNestedMenu(false);
  });

  const currentUser =
    (user?.firstName && getUserFullName(user.firstName)) ??
    user?.email?.charAt(0).toUpperCase();

  const userNestedMenuClass =
    'h-9 py-2 px-2.5 p-2 text-sm hover:bg-gray-100 hover:rounded-sm w-full flex justify-start text-gray-800';
  const profilePictureUrl = user?.avatar;
  return (
    <div
      className='ml-3 flex items-center gap-1 2lg:ml-6'
      onClick={toggleUserProfileMenu}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          toggleUserProfileMenu();
        }
      }}
      role='button'
      tabIndex={0}
      ref={userProfileNestedMenuRef}
      data-cy='header-user-profile'
    >
      <Avatar
        avatar={[
          {
            imageSrc: profilePictureUrl,
            name: 'User avatar image',
          },
        ]}
        defaultAvatarLetter={user?.firstName?.charAt(0).toUpperCase() ?? 'V'}
      />
      <div
        data-cy={`current user: ${currentUser}`}
        className='hidden whitespace-nowrap 2lg:block'
      >
        {currentUser}
      </div>
      <ImageComponent
        src={chevronDownUserProfileHeader}
        alt='User Menu Dropdown Click Icon'
        classes='sm:ml-2 ml-1'
        width={12}
        height={7}
      />
      {userProfileNestedMenu && (
        <div className='absolute right-5 z-10 mt-36 h-[88px] rounded-lg border-2 border-gray-100 bg-white p-2 shadow-lg'>
          <Link to={ROUTES.SETTINGS.PERSONAL_SETTINGS}>
            <div className={userNestedMenuClass}> Personal Setting</div>
          </Link>
          <Button
            intent='tertiary'
            onClick={() => setShowModal(true)}
            size='xs'
            className={userNestedMenuClass}
            variant='gray'
          >
            Logout
          </Button>
        </div>
      )}
      <LogoutModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
};

const HeaderRightSection = () => {
  const { v12SSEEventCount } = useNotificationStore();

  return (
    <div className='ml-auto flex items-center gap-2 px-3 py-3.5 sm:gap-0 2lg:px-6'>
      <div className='b-l-1 mx-2 hidden h-9 border text-gray-200 sm:block 2lg:mx-5' />
      <div className='flex gap-2 sm:gap-4 2lg:gap-6'>
        <NavLink
          to={`/${ROUTES.CART}`}
          className='relative'
        >
          <IconsComponent icon='fill-cart' />

          {v12SSEEventCount.cartItemCount > 0 && (
            <div className='absolute bottom-3 left-4 grid aspect-square min-h-5 min-w-5 items-center justify-center rounded-full bg-yellow-400'>
              <p className='p-[2px] text-xs font-normal text-gray-800'>
                {v12SSEEventCount.cartItemCount > 99
                  ? '99+'
                  : v12SSEEventCount.cartItemCount}
              </p>
            </div>
          )}
        </NavLink>
        <HeaderNotification />
      </div>
      <UserProfile />
    </div>
  );
};

const Header = () => {
  const isSidebarVisible = useSidebarStore((state) => state.isSidebarVisible);

  return (
    <header
      className={cn(
        'sticky top-0 flex min-h-[72px] items-center border-b-2 border-gray-200 bg-white pr-2 2lg:pr-0',
        isSidebarVisible ? 'z-20' : 'z-90',
      )}
      data-cy='header'
    >
      <SidebarTogglerIcon />
      <div className='ml-auto w-full max-w-[704px]'>
        <HeaderSearchBox />
      </div>
      <HeaderRightSection />
    </header>
  );
};

export default Header;
