import { useState } from 'react';

import { Alert } from '@/components';
import { LoginPage } from './LoginPage';
import VerifiyCode from './VerifyCodePage';
import { PersonalInfoForm } from './PersonalInfoForm';
import { LoginStep } from '@/utils/constants';
import { useAlert } from '@/components/hooks';
import { Generate2FAPage } from './Generate2FAPage';

const AuthPage = () => {
  const { isAlertVisible, isAlertDismissed, alertMessage, isType } = useAlert();

  const [loginStep, setLoginStep] = useState<LoginStep>(LoginStep.LOGIN);

  return (
    <>
      {isAlertVisible && !isAlertDismissed && (
        <Alert
          type={isType}
          classes='mt-4 mb-1.5'
          title={alertMessage}
        />
      )}
      <div className='flex items-center justify-center py-24'>
        {(() => {
          switch (loginStep) {
            case LoginStep.LOGIN:
              return <LoginPage setLoginStep={setLoginStep} />;
            case LoginStep.VERIFY:
              return <VerifiyCode setLoginStep={setLoginStep} />;
            case LoginStep.TWOFA:
              return <Generate2FAPage />;
            case LoginStep.PERSONAL_SETTINGS:
              return <PersonalInfoForm setLoginStep={setLoginStep} />;
            default:
              return null;
          }
        })()}
      </div>
    </>
  );
};

export default AuthPage;
