export function toQueryString(params: Record<string, unknown>): string {
  const filteredParams = Object.entries(params).reduce<Record<string, string>>(
    (acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    },
    {},
  );

  return new URLSearchParams(filteredParams).toString();
}
