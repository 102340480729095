import { Avatar } from '@/components';
import { TTeamSchema } from '@/schemas/search/search.schema';
import { ROUTES } from '@/utils/constants';
import { highlightMatchingText } from '@/utils/helper/highlightText.helper';
import { Link } from 'react-router-dom';
interface AvatarProps {
  id: string | null | undefined;
  imageSrc: string | null | undefined;
  name: string | undefined;
}

const TeamSearchList = ({
  searchValue,
  data,
}: {
  searchValue: string;
  data: TTeamSchema;
}) => {
  const avatars: AvatarProps[] =
    data.users?.map((user) => ({
      id: user.id ?? null,
      imageSrc: user.avatar ?? '',
      name: user.firstName ?? 'n/a',
    })) ?? [];

  return (
    <div className='team-search-list relative mb-4 flex w-full items-center gap-5 overflow-hidden rounded-md bg-white p-5 shadow-sm'>
      <div>
        <div className='mb-2'>
          <Link
            to={`/${ROUTES.USER_AND_TEAMS.TEAM.LIST}/id/${data.id}`}
            className='inline-flex w-auto p-0 text-base font-medium text-gray-800 hover:bg-transparent hover:text-blue-600 hover:underline'
          >
            <span>{highlightMatchingText(data.name ?? '', searchValue)}</span>
          </Link>
        </div>
        <div className='flex w-full items-center justify-start gap-3'>
          <Avatar
            avatar={avatars}
            size={30}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamSearchList;
